


h1 {font-size:30px}
h2 {font-size:24px}
h3 {font-size:20px}
h4 {font-size:16px}
h5 {font-size:14px}
h6 {font-size:13px}





//  FORMAT CLASS
// ______________________________________




$format__p__font_size: 		17px;
$format__p__font_size_m:	15px;
$format__p__font_weight: 	$normal;
$format__p__color: 			darken(#636363, 0%);
$format__p__letter_spacing: 0px;
$format__p__word_spacing: 	0px;
$format__p__line_height: 	1.9;
$format__p__margin_top: 	0px;
$format__p__margin_bottom: 20px;

$format__a__font_size: 		16px;
$format__a__font_weight: 	$normal;
$format__a__color: 			$touche1;

$format__h__font_weight : 	$bold;
$format__h__font_family: 	$family1;
$format__h__color : 		#0f205a;
$format__h__letter_spacing: 1.5;
$format__h__text_transform: none;
$format__h__margin_top: 	0px;
$format__h__margin_bottom: 20px;


p, li, a, h1, h2, h3, h4, h5, h6 {
	color:$grey;
}
p {
	font-size: $format__p__font_size;
	font-weight: $format__p__font_weight;
	line-height: $format__p__line_height;
	color: $format__p__color;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	margin:0;
	margin-top: $format__p__margin_top;
	margin-bottom: $format__p__margin_bottom;
	@include brk($m) {
		font-size: $format__p__font_size_m;
		text-align: left!important;
		line-height: $format__p__line_height - 0.1;
		margin-bottom: $format__p__margin_bottom - 5px;
	}
	span {
		-webkit-text-stroke: $format__p__color;
	}
}
blockquote {
	padding:40px 1% 30px;
	p {
		font-size:$format__p__font_size;
		font-weight:$semi-bold;
		color:$format__a__color;
		text-align:left;
		// font-style: italic;
		line-height:1.8;
	}
}
a {
	font-size: $format__a__font_size;
	font-weight: $format__a__font_weight;
	color: $format__a__color;
	line-height: $format__p__line_height;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	-webkit-text-stroke: $format__a__color;
	text-decoration: underline;
	// font-style:italic;
	margin-left: 2px;
	margin-right: 2px;
	position: relative;
	// &::after {
	// 	content:"";
	// 	display: inline-block;
	// 	position: absolute;
	// 	height:1px;
	// 	left:0;
	// 	bottom:3px;
	// 	width:0;
	// 	opacity: 0;
	// 	background: $format__a__color;
	// 	transition: width 0.1s, opacity 1s;
	// }
	// &:hover::after {
	// 	width:100%;
	// 	opacity:0.6;
	// }
	// @include brk($td) {
	// 	&::after {
	// 		width:100%;
	// 		opacity:0.4;
	// 	}
	// }
	@include brk($m) {
		font-size: $format__p__font_size_m;
		text-align: left!important;
		line-height: $format__p__line_height - 0.1;
	}
}

h2, h3, h4 {
	font-weight: $format__h__font_weight;
	font-family: $format__h__font_family;
	color:$format__h__color;
	line-height:1.6;
	margin-top:$format__h__margin_top;
	margin-bottom:$format__h__margin_bottom;
	letter-spacing: $format__h__letter_spacing;
	text-transform: $format__h__text_transform;
	a {
		font-size:inherit;
	}
}
h5, h6 {
	font-weight: $bold;
	text-transform: uppercase;
}
li {
	font-size: $format__p__font_size;
	font-weight: $format__p__font_weight;
	color: $format__p__color;
	line-height:$format__p__line_height;
	list-style-type:none;
	// display: table-row;
	margin-bottom:5px;
	vertical-align: top;
	padding-left:15px;
	position: relative;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	// &::after {content:"";display:table-row;height:10px;}
	@include brk($m) {
		font-size: $format__p__font_size_m;
		line-height: $format__p__line_height - 0.1;
	}
}
ul, ol {
	padding: 0px 0px 20px 0px;
	display:table;
}
ul {
    li::before {
        content: '•';
        color: $format__p__color;
        font-weight: $bold;
        position: absolute;
		left: 0px;
		top: 0px;
    }
}
ol {
    // list-style-position: inside;
    margin-left:20px;
    counter-reset: foo;
    li {
        counter-increment: foo;
        padding-left:17px;
        &::before {
        	content: counter(foo) ".";
        	position: absolute;
			left: 2px;
			top: 0px;
        }
    }
}

hr {
	border-top: 0.5px inset transparentize(#555, 0.8);
	margin-top: 8px;
	margin-bottom: 25px;
	border-left:none;
	border-bottom:none;
	border-right:none;
}

iframe {
	width:100%;
	height:auto;
}

p img {
	width:100%;
	height:auto;
}

.wp-caption {
    background: #f1f1f1;
    max-width:100%;
    margin: 10px 0 15px 0px;
    img {
    	width:100%;
    	height:auto;
    }
    p {
    	font-size: 11px;
    	font-style: italic;
    	text-align: center;
    	margin-bottom: 0;
    	color:transparentize(#000, 0.5);
    	line-height: 1;
    	padding: 10px 0;
    	margin-top: -5px;
    }
	&.alignleft {
		float: left;
		width: auto;
		margin: 10px 30px 15px 0;
	}
	&.alignright {
		float: right;
		width: auto;
		margin: 10px 0 15px 30px;
	}
	&.aligncenter {
		margin: 20px auto 30px;
		display: block;
	}
}
