


#wpadminbar {
    background: transparentize(#23282d, 0.05)!important;
    width: auto!important;
    min-width: 0px!important;
    padding-right: 10px;
    border-bottom-right-radius: 13px;
    &:hover {
        background: transparentize(#23282d, 0.05)!important;
    }
    #wp-admin-bar-root-default>li a::before {
        transition:none!important;
    }
    #wp-admin-bar-root-default>li.hover a {
        & { color:#eee!important }
        &::before { color:rgba(240,245,250,.6)!important; }
    }
    #wp-admin-bar-root-default>li a:hover {
        &, &::before {
            color: #00b9eb!important;
        }
    }
    #wp-toolbar, #wp-admin-bar-root-default {
        height:100%;
    }
    .ab-sub-wrapper,
    #wp-admin-bar-new_draft,
    #wp-admin-bar-top-secondary {
        display: none!important;
    }
    .ab-sub-wrapper,
    #wp-admin-bar-all-in-one-seo-pack,
    #wp-admin-bar-search {display:none}

    #wp-admin-bar-site-name a::before {
        top:3px!important;
        @include brk($m) {
            top:7px!important;
        }
    }
    #wp-admin-bar-my-log-out {
        margin-left: 7px;
        a.ab-item {
            // padding-right: 25px;
            position: relative;
            img {
                display: none;
                height: 16px;
                position: absolute;
                top:8px;
                right:0px;
            }
            &::before {
                top:3px;
                left:2px;
                content: "\f242" !important;
                transform: scale(0.95);
            }
        }
    }
}