


.archive {
	background:#fff;
}





// BANNER TOP

$page_wrapper-bannerTop-desktop: 482px;
$page_wrapper-bannerTop-laptop: 460px;
$page_wrapper-bannerTop-tablet: 482px;
$page_wrapper-bannerTop-mobile: 236px;



.archive .banner.top {
    background:$footer-color;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.archive .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}






.archive .banner.top {
	// padding-top:50px;
	// animation: fade 1s;
	transition:height 1s;
	.banner-content {
		@include brk($t) {
			padding:0;
		}
	}
	.content {
		height:100%;
		position: relative;
	}
}








.archive .banner.medium {
	margin-top:-5px;
	background:#ffffff;
	padding:182px 0 130px;
	// margin-top:-5px;
	@include brk($t) {
		padding:80px 0 90px;
	}
    @include brk($td) {
        padding:65px 0 50px;
    }
	@include brk($m) {
		padding:55px 0 50px;
        .banner-content {
            padding:0;
        }
	}
    .col-left {
        display: inline-block;
        position: relative;
        width: 33%;
        float: left;
        @include brk($td) {
            width:100%;
            float: none;
            margin-bottom: 30px;
        }
        @include brk($m) {
            padding: 0 6%;
        }
    }
    .col-right {
        display: inline-block;
        position: relative;
        width: 67%;
        float: right;
        @include brk($td) {
            width:100%;
            float: none;
        }
    }
	.fond {
        background-image:url('../assets/images/background-home.jpg');
        background-size: cover;
        background-position:50% 0%;
        position: absolute;
        top:0;
        left:0;
        right:0;
        height: 100%;
        width: 100%;
        animation: fade 1s;
        @include brk($m) {
            display: none;
        }
    }
	.content {
		position: relative;
	}
    .col-left {
        .sur-titre {
            font-size: 10px;
            color:$touche1;
            text-transform: uppercase;
            letter-spacing: 3px;
            margin-bottom: 30px;
        }
    	.titre-type {
    		font-size: 47.5px;
            font-weight: $thin;
            font-family: $family1;
            color: $touche2;
            line-height: 1.1;
            text-transform: uppercase;
            letter-spacing: 0px;
            margin-top: 5px;
            margin-bottom: 30px;
            @include brk($td) {
                font-size: 40px;
            }
            @include brk($m) {
                font-size: 32.5px;
            }
    	}
    	.lien {
    		// animation: fade 2s;
    		span {
    			font-size: 24px;
    			font-weight: $bold;
    			color:$touche1;
    			margin-left: 20px;			
    			display:inline-block;
    			transform: translateY(2px);
    		}
    		font-size: 11px;
    		font-weight: $bold;
    		letter-spacing: 1px;
    		color:#0f205a;
    		text-transform: uppercase;
    		padding: 5px 0;
            &:hover {
                &, & span {
                    color:$touche1;
                }
                span {
                    color:$touche2;
                }
            }
    	}
    }
}
























// LISTE TYPE 3 ----------------------------------------------


.archive .banner.medium {
    .fond-illustration {
        animation: fade 2s;
        position: absolute;
        left:0;
        bottom:0;
        width:65%;
        height:1050px;
        background-size:contain;
        background-position:0% 100%;
        @include brk($td){display:none;}
    }
	ul {
        background:#fff !important;
		padding-top:10px;
        min-height: 250px;
        li {
            height:280px;
            width:100%;
            float: left;
            background:#fff;
            margin-bottom: 30px;
            overflow: hidden;
            @include brk($td) {
                width:100%;
                float: none!important;
            }
            @include brk($m) {
                height:auto;
                min-height:195px;
                margin-bottom: 0px;
                box-shadow:none;
            }
            &:nth-child(2n) {float:right;}
            .color-sticker {
                height:200px;
                width: 400px;
                position: absolute;
                top:-70px;
                right:-400px;
                transition: right 0.3s;
                background:$touche1;
                z-index: 100;
                transform:rotate(45deg);
            }
            .plus-container {
                height: 11px;
                width: 11px;
                transform:rotate(45deg);
                position: absolute;
                top:10px;
                right:10px;
                z-index: 101;
                animation: fade 0.5s;
                display: none;
                .st0 {stroke:#fff!important; stroke-width:3px}
            }
            .image-container {
                width:100%;
                position: absolute;
                left:0;
                top:0;
                height:100%;
                filter: brightness(90%);
                transition: filter 0.5s;
                @include brk($td) {
                    filter: brightness(100%);
                }
                @include brk($m) {
                    height:195px;
                    width:100%;
                    position: relative;
                }
                .image {
                    transition: box-shadow 0.1s;
                    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
                    // animation: none;
                    // filter: contrast(90%);
                }
            }
            .gradient {
            	position: absolute;
                bottom:0;
                left:0;
                right:0;
                height:150px;
            	// animation: fade 0.15s;
                background: linear-gradient(to top, 
                    transparentize(#000, 0.3) 10%, 
                    transparentize(#000, 0.4) 30%, 
                    transparentize(#000, 0.6) 50%, 
                    transparentize(#000, 0.82) 70%, 
                    transparentize(#000, 1) 100%);
            }
            .textes-container {
                width:100%;
                position: absolute;
                left:0;
                bottom:0;
                padding: 0px 35px;
                @include brk($m) {
                    width:100%;
                    float: none!important;
                    position: relative;
                    bottom:auto;
                    background:#fff;
                    padding: 25px 6%;
                }
                .bloc-container {
                    height:100px;
                    line-height: 100px;
                    @include brk($m) {
                        height:auto;
                        line-height:1;
                    }
                }
                .date {
                    font-size: 10px;
                    font-weight: $bold;
                    color:#fff;
                    letter-spacing: 1.5px;
                    display: inline-block;
                    margin-right: 4px;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    display: none;
                    &::after {
                    	content:"";
                    	display: inline-block;
                    	background:#fff;
                    	height:1px;
                    	width:8px;
                    	margin-left: 5px;
                    	transform: translateY(-2.5px);
                    }
                    &.agenda::after {display:none;}
                    @include brk($m) {
                        color:#20b196;
                    }
                }
                .titre-post {
                    font-size: 18.5px;
                    font-weight: $normal;
                    color:#fff;
                    line-height:1.25;
                    position: relative;
                    padding: 10px 0;
                    @include brk($t) {
                    	line-height:1.2;
                    }
                    @include brk($td) {
                    	// line-height:1.4;
                        font-size: 17px;
                    }
                    @include brk($m) {
                    	font-size: 16px;
                        margin-bottom: 10px;
                        color:#262626;
                    }
                    &::before {
                        content:"";
                        position: absolute;
                        background: $touche1;
                        top:0px;
                        left:-35px;
                        height:calc(100%);
                        width:5px;
                        animation: fade 2s;
                        @include brk($tp) {
                            left:1px;
                        }
                    }
                }
            }
            &:hover {
                .image {box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);}
                .image-container {
                    filter: brightness(100%);
                }
                .lire-la-suite {
                    opacity: 1;
                    // color:$orange;
                }
                .color-sticker, .plus-container {
                    display: inline-block;
                }
                .color-sticker {
                    right:-310px;
                }
            }
        }
    }
}







