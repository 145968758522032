
.page.contact {
    background:#fff;
}



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 393px;
$page_wrapper-bannerTop-laptop: 393px;
$page_wrapper-bannerTop-tablet: 393px;
$page_wrapper-bannerTop-mobile: 393px;

.page.contact .banner.top {
	transition: height 1s;
	height:$page_wrapper-bannerTop-desktop;
	@include brk($l) { height:$page_wrapper-bannerTop-laptop; }
	@include brk($t) { height:$page_wrapper-bannerTop-tablet; }
	@include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.page.contact .banner.top .bloc-container {
	transition: line-height 1s;
	line-height:$page_wrapper-bannerTop-desktop;
	@include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
	@include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
	@include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}



.page.contact .banner.top {
	position: relative;
    .loader-circle, .loader-line-mask {
        top: calc(50% - 120px);
    }
	.image {
		background-position: 50% 32% !important;
        background-size: cover !important;
	}
	.bloc {
		@include brk($t) {
			padding: 25px 0 0 0;
		}
	}
	.sur-titre {
		animation: fade 2.5s;
		font-size: 19px;
		color:$touche1;
		letter-spacing: 0.5px;
		font-weight: $light;
		position: relative;
		z-index: 4;
        margin-bottom:6px;
		@include brk($t) { 
			animation: fade_80 2.5s;
			opacity:0.8;
			// color:#fff;
		}
		@include brk($m) { font-size: 18px; }
	}
	.titre {
		animation: fade 2s;
		font-size:32.5px;
		font-weight: $bold;
        font-family: $family2;
		letter-spacing: 0.5px;
		color:#555868;
        line-height: 1.35;
		position: relative;
		z-index: 4;
		&:before {
            content:"";
            display: inline-block;
            height:3px;
            width:20px;
            position: absolute;
            left:-40px; top:27px;
            background:$touche1;
            @include breakpoint ($t) {
                display: none;
            }
        }
		@include brk($t) { font-size: 35px; }
		@include brk($m) { font-size: 25px; }
	}
	#google_map {
        animation: fade 2.5s;
        height:100%;
        width:100%;
        // height:390px;
        position: absolute!important;
        /*======= Map Styling ============*/
        .gmnoprint a, .gmnoprint span {
            display:none;
        }
    }
    .gradient {
        position: absolute;
        height:40px;
        top:auto;
        bottom:0;
        left:0;
        right:0;
        z-index: 900;
        background: linear-gradient(to bottom, 
            transparentize(#000, 1) 0%, 
            transparentize(#000, 0.95) 85%,
            transparentize(#000, 0.88) 100%
        );
    }
}










.aplat-contact {
	position: absolute;
	right:0px;
	top:-82px;
	background:$touche1;
	height:82px;
	width:100%;
	z-index: 10;
	animation: fade 1s;
	@include brk($t) {
		top:auto;
        position: relative;
        width:100%;
        left:0;
        bottom:auto;
        z-index: 40;
	}
	@include brk($t) {
		height:70px;
	}
	.icon {
		height:56px;
		width:56px;
		border-radius:50%;
		position: absolute;
		left:67px;
		top:14px;
		background: transparentize(#fff,0.85);
		svg {
			transform: scale(0.41);
			path {
				fill:#fff;
			}
		}
		@include brk($t) {
			left:5.5%;
			top:8px;
		}
	}
	p {
		color:#fff;
		font-weight: $light;
		font-size: 20.5px;
        letter-spacing: 1px;
		padding-left:150px;
		padding-top:26px;
        text-transform: uppercase;
		@include brk($t) {
			padding-left: calc(5.5% + 80px);
			font-size: 18px;
			padding-top:22px;
		}
	}
}




.page.contact .banner.top {
	.image { 
		animation: fade 1s; 
		background-position: 50% 20%;
	}
}



.page.contact .banner.medium {
	overflow:visible;
    background:#f7f7f7;
	padding-top:0;
	animation:none;
    margin-top: -5px;
	.banner-content {
		@include brk($t) { padding:0 }
		.content {
			position: relative;
		}
	}
}




















// COLONNES ------------------------------------

.page.contact {
    .shadow-side {
        display: inline-block;
        height:100%;
        width: 40px;
        position: absolute;
        top:0;
        left:-40px;
        z-index:100;
        background-position:100% 50%;
        background-size: contain;
    }
    .col-side {
        width:30%;
        float: left;
        background:#fff;
        animation: fade 2s;
        height:100%;
        display: inline-block;
        @extend .cache-left;
        @extend .cache-bottom-left;
        >.inside {
            display: inline-block;
            padding: 70px 0% 80px 20%;
            text-align: left;
            animation: fade 1s;
            @include brk($t) { 
                padding: 50px 0% 40px 0;
                padding-left:6%;
                padding-right:6%;
            }
            @include brk($m) { 
                text-align: left; 
                padding: 40px 0% 30px 0;
                padding-left:6%;
                padding-right:6%;
            }
        }
        
    }
    .col-main {
        width:70%;
        float: right;
        background:$contact_form_background;
        padding: 90px 0px 70px 130px;
        animation: fade 1.5s;
        @extend .cache-right;
        @extend .cache-bottom-right;
        @include brk($t) { 
            padding: 60px 6% 50px;
            width: 100%; }
        @include brk($m) {
            padding: 40px 6% 0px;
            padding-left:$padding-mobile;
            padding-right:$padding-mobile;
            width: 100%;
        }
    }
}









.page.contact .col-side {
    min-height: 300px;
    z-index: 21;
    @include brk($t) { 
        width: 100%; 
        &::after {display:none;}
    }
    @include brk($m) {  
        width: 100%;  
    }
    .col-temp {
        @include brk($t) {
            width:50%;
            // float: left;
        }
        @include brk($tp) {
            width:100%;
            float: none;
        }
    }
    .titre-colonne {
        font-size: 13.5px;
        font-weight: $normal;
        font-family: $family2;
        color:#003359;
        letter-spacing: 2px;
        position: relative;
        margin-bottom: 25px;
    }
    .sur-titre {
        font-size: 12px;
        color:#8f8f8f;
        font-weight: $light;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    .nom-entreprise {
        font-size: 25px;
        color:$touche2;
        font-weight: $bold;
        line-height: 2;
        text-transform: uppercase;
        span {
            font-weight: $light;
            color:#4a4337;
        }
    }
    .personne_de_contact {
        padding:6px 0px 7px;
        color:$touche2;
        font-size: 15px;
    }
    .sous-titre {
        font-size: 20px;
        color:$touche3;
        font-weight: $light;
        letter-spacing: 0px;
        margin-bottom: 30px;
    }
    .titre-section {
        font-size: 17.5px;
        font-weight: $bold;
        color:$touche1;
        padding-top: 8px;
        margin-bottom: 10px;
    }
    .coordonnees-container {
        padding-bottom:8px;
        .icon {
            width:30px;
            height:30px;
            position: absolute;
            top:1px;
            left:-7px;
            svg {
                transform: scale(0.5);
            }
            .st0 {
                fill:$touche1;
            }
        }
        span {
            font-size: 15px;
            font-weight: $bold;
            font-family: $family1;
            display:block;
            color:$touche3;
            position: relative;
            &.tel, &.email {
                padding-left: 26px;
                padding-bottom:3px;
                p, a{font-size: 17px;font-weight:$bold;}
            }
            &[itemprop="address"] {
                padding-top: 10px;
                margin-bottom: 10px;
                p { 
                    display: block; 
                    margin-left:0px;
                }
            }
            p, a {
                font-size: 15px;
                font-weight: $regular;
                font-family: $family1;
                color:$touche3;
                line-height: 1.8;
                display: inline;
                margin-left:5px;
            }
            a {
                position: relative;
                &::after {
                    content:"";
                    display: inline-block;
                    position: absolute;
                    bottom:1px;
                    left:0;
                    height:2px;
                    width:0;
                    opacity: 0;
                    transition: opacity 0.5s, width 0.2s;
                    background: lighten($touche1, 0%);
                }
                &:hover {
                    &::after {
                        width:100%;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .petit-trait {
        height: 2.5px;
        background:$touche1;
        margin-top: 17px;
        margin-bottom:18px;
        width:21px;
    }
    .trait {
        height: 13px;
        border-bottom:1px solid transparentize(#000, 0.9);
        margin-bottom:16px;
        width:80%;
        @include brk($t) {  
            width:100%;
            // display: none;
        }
    }
    .googlemap-container {
        display: inline-block;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
        @include brk($t) { margin-top: 40px; }
        @include brk($m) { margin-top: 42px; }
        p {
            font-size: 15px;
            font-weight: $bold;
            float: left;
            width:120px;
            margin-top: 16px;
            display: inline-block;
            margin-left: 15px;
            color:$touche3;
            transition: color 1s;
            text-transform: uppercase;
            letter-spacing: 1px;
            user-select:none;
            @include brk($m) { 
                float: left; 
            }
        }
        .icon-container {
            float: left;
            height:54px;
            width: 54px;
            background: transparentize($touche1, 0.95);
            border-radius: 50%;
            text-align: center;
            margin-right: 0px;
            transition: transform 0.15s;
            @include brk($m) { float: left; }
            .icon {
                margin-top: 13px;
                width:28px;
                height:28px;
                transition: transform 5s;
            }
            path {
                fill:$touche1;
                transition: fill 0.2s;
            }
        }
        &:hover {
            // p {color:$touche2}
            .icon-container {transform: scale(0.98)}
            .icon {transform: scale(1.2)}
            path {
                transition: fill 0.2s;
                fill:$touche2
            }
        }
    }
}
















.page.contact .col-main {
	.format {
		padding: 0px 0 50px 12%;
	    display: inline-block;
	    width: 100%;
	    @include brk($t) {
	        padding: 0 9% 55px;
	    }
	    @include brk($m) {
	        padding: 0 6%;
	        margin-top: 40px;
	        margin-bottom:40px;
	    }
	}
}









