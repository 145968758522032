

// FORM BACKGROUND 
$contact_form_background: #f6f6f6;


// FONT-SIZES
$input_fontsize-desktop: 17px;
$input_fontsize-tablet: 16px;
$input_fontsize-mobile: 15px;



// PADDING-LEFT
$field_padding_left: 44px !important;





.contact-form {
    margin-top: 0px;
    margin-bottom:100px;
    display: inline-block;
    width: 100%;
    min-height:700px;
    @include brk($t) {
        margin-bottom:80px;
        padding:0;
    }
    
    .sur-titre {
        margin-bottom: 25px;
        p {
            font-size: 21px;
            // font-style: italic;
            font-weight: $bold;
            font-family: $family1;
            letter-spacing: 2.2px;
            // text-transform: uppercase;
            color:$touche1;
            @include brk($m) {
                font-size: 16px;
                padding-right: 20%;
            }
        }
    }
    .titre {
        font-size:25px;
        font-weight: $thin;
        font-family: $family1;
        color:$touche3;
        letter-spacing: 0px;
        line-height: 1.2;
        position: relative;
        text-transform: uppercase;
        margin-bottom: 45px;
        span { color:$touche1; }
        @include brk($m) {
            font-size:24px;
            margin-bottom: 35px;
        }
    }
    form {
        width:100%;
        max-width: 900px;
        min-height:280px;
        margin-top: 0px;
        margin-bottom: 45px;
        clear: both;
        @extend .clearfix;
        @include brk($t) {
            max-width: none;
        }
        @include brk($m) {
        }
        .container {
            display: inline-block;
            width:100%;
            margin-bottom: 15px;
            position: relative;
            @include brk($m) { margin-bottom: 10px; }

            label {
                font-size: 14px;
                font-weight: $bold;
                font-family: $family1;
                color:$touche3;
                letter-spacing: 1px;
                display: inline-block;
                margin-bottom:20px;
                display: none!important;
            }
            input, textarea { 
                position: relative;
                user-select: text; 
            }

            // FONT -----------------------------
            input, textarea, select, .selectize-input .item, .option {
                font-weight: $normal;
                font-family: $family1;
                color:$touche2;
                letter-spacing: 0px;
            }
            .selectize-input .item { padding-top:15px; }
            .option {
                font-size: 14.5px;
                font-weight: $normal;
                color:$touche2;
                padding: 17px 21px;
            }
            
            $box_shadow: 1px 1px 4px 0px transparentize(#000, 0.95);
            input, textarea, select, .selectize-input {
                width:100%;
                z-index: 10;
                padding:0 20px;
                transition: border 0.5s, box-shadow 0.15s, padding-left 0.3s;
                border:1px solid transparentize(#000, 0.9);
                border-radius:12px;
                background:#fff !important;
                box-shadow: $box_shadow;
                &.ng-pristine {
                    border:1px solid #fff!important;
                }
                &.focus, &:focus {
                    // border:1px solid transparentize($touche2, 0.8);
                    box-shadow: 2px 2px 4px 1px transparentize($touche2, 0.81) !important;
                }
            }

            input, select {
                height:54px;
                @include brk($m) { height:50px; }
            }   

            input {
                font-size: $input_fontsize-desktop;
                will-change: box-shadow, background, padding-left;
                @include brk($td) { font-size: $input_fontsize-tablet; }
                @include brk($m) { font-size: $input_fontsize-mobile; }
            }


            // FIX FOR CHROME AUTOFILL
            input, textarea {
                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0px 1000px #fff inset, $box_shadow !important;
                    -webkit-text-fill-color: $touche2 !important;
                    border: 1px solid #fff;
                    &:focus {
                    }
                }
            }

            // Turn Off Number Input Spinners
            input[type=number]::-webkit-inner-spin-button, 
            input[type=number]::-webkit-outer-spin-button { 
                -webkit-appearance: none; 
                margin: 0; 
            }

            
            $form_half_width: 49%;
            &.nom, &.email, &.rue, &.telephone, &.ville, &.entreprise {width:$form_half_width;float:left;}
            &.prenom, &.nombre-employes, &.code-postal {width:$form_half_width;float:right;}
            &.left {width:$form_half_width;float:left;}
            &.right {width:$form_half_width;float:right;}
            &.full {width:100%; float:left}
            @include brk($m) {
                width:100% !important;
                float: none!important;
                clear: both;
            }
        }
        legend {
            font-weight: $bold;
            font-family: $family2;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-top: 30px;
            margin-bottom: 30px;
            clear:both;
            float: left;
            width:100%;
            position: relative;
            &::before {
                content:"";
                display: inline-block;
                position: absolute;
                top:8px;
                left:-39px;
                height:1px;
                width:21px;
                transition: opacity 0.5s, width 0.2s;
                background: darken($touche1, 0%);
                @include brk($tp) {display:none}
            }
        }
        textarea {
            font-size: $input_fontsize-desktop;
            resize:none!important;
            padding-top:30px!important;
            padding-right: $field_padding_left;
            text-align: justify;
            line-height: 1.5;
            height:auto;
            will-change: box-shadow, background, height, padding-left;
            transition: border 0.3s, height 0.5s, min-height 0.5s, padding-left 0.3s !important;
            max-height:500px;
            min-height: 150px;
            &:focus { min-height: 170px; } 
            @include brk($td) { font-size: $input_fontsize-tablet; }
            @include brk($m) { 
                font-size: $input_fontsize-mobile; 
                text-align: left;
            }
        }
        .container.message {
            margin-bottom: 15px;
        }
        .champs-requis {
            text-align: right;
            margin-bottom: 0px;
            float: right;
            p {
                font-size: 14px;
                font-weight: $light;
                font-style: italic;
                color:$touche1;
            }
            @include brk($m) {display:none;}
        }
        button[type="submit"] {
            width:250px;
            padding: 0 0 0 0px;
            background:$touche1;
            height:62px;
            text-align: left;
            // border-radius:12px;
            margin-top: 30px;
            overflow:hidden;
            @include brk($m) {
                width:100%;
                float: none;
            }
            p {
                font-size:14px;
                font-weight: $bold;
                font-family: $family1;
                padding:21px 90px 19px 30px;
                text-transform: uppercase;
                color:#fff;
                letter-spacing: 2px;
                text-align: left;
                text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
            }
            .icon {
                position: absolute;
                right:0;
                top:14.5px;
                width: 34px;
                color:#fff;
                font-size:25px;
                font-weight: $normal;
                user-select:none;
                &::before {
                    content:"";
                    height: 33px;
                    width:1px;
                    background:transparentize(#fff, 0.85);
                    display: inline-block;
                    position: absolute;
                    top:0px;
                    left:-22px;
                }
            }
            &:hover, &:focus {
                background: $touche2;
            }
            &.message-sent {
                background: desaturate(green, 0%)!important;
                p {
                    padding-right:20px;
                    &.sent {animation: fade 0.5s;}
                }
            }
        }
    }
}


















// INPUT SELECT  (Selectize) --------------------------------


.contact-form {
    .container.you_are {
        // margin-bottom: 35px;
        // @include brk($m) { margin-bottom: 30px; }
        label { margin-top: 15px; }
        .selectize-control {
            // width:calc(100% - 115px);
            // float: right;
            @include brk($m) {
                float: none;
                width:100%;
            }
            .selectize-input {
                input {display:none !important;}
                &::after {
                    height:22px;
                    width:22px;
                    border:none;
                    top:18px;
                    right:30px;
                    margin-top: 0;
                    background:url('../assets/images/select-dropdown.svg');
                }
            }
            .selectize-dropdown {
                overflow: hidden;
                border-radius:10px;
                margin-top: 0;
                .selectize-dropdown-content { padding:0; }
            }
        }
    } 
}













// ADRESSE DE FACTURATION IDENTIQUE -----------------------

.identical-address {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 30px;
    input[type="checkbox"] {
        display: none;
        + label {
            height:40px;
            line-height: 40px;
            padding-left:37px;
            display: inline-block;
            position: relative;
            width: 100%;
            user-select:none;
            cursor:pointer;
            p {
                font-size: 11px;
                font-weight: $bold;
                font-family: $family2;
                color: lighten(#2b2a27, 8%);
                text-transform: uppercase;
                letter-spacing: 1px;    
                line-height: 1.8;
                cursor:pointer;
                @extend .bloc;
            }
            .case {
                width:24px;
                height:24px;
                border-radius:50%;
                text-align: center;
                position: absolute;
                left:-3px;
                top:9px;
                padding:8px;
                background:transparentize(#000, 0.95);
                @include brk($t) {left:0px;}
            }
            .case span {
                background: $touche1;
                display: block;
                height:100%;
                transition: opacity 0.05s;
                border-radius:50%;
                opacity: 0;
            }
        }
        &:checked + label {
            .case span { opacity:1 }
        }
    }
}









// FICHIERS --------------------------------------------


.contact-form .container.files {
    margin-bottom: 0;
    display: inline-block;
    width:100%;
    position: relative;
    min-height:10px;
    // border-bottom:1px solid rgba(0,0,0,0.10);
    // width: calc(100% - 60px);
    input {
        height:70px;
        width:100%;
        line-height: 2;
        transition: all 0.30s ease-in-out;
        @include brk ($mobile){
            height:41px;
        }
    }
    input.file::-webkit-file-upload-button {
        visibility: hidden;
    }
    input[type="file"] {
        display: none;
    }
    label, .attacher-vos-docs {
        display: inline-block;
        padding-top:10px;
        padding-bottom:25px;
        line-height: 1.5;
        width:100%;
        color:#797979;
        font-weight: $light;
        padding-right:80px;
    }
    .icon-container {
        display: inline-block;
        width:40px;
        height:40px;
        background:$touche1;
        border-radius:50%;
        position: absolute;
        right:0px;
        top:71px;
        text-align: center;
        transform: scale(1.1);
        transition: transform 0.3s;
        @include brk ($t){ top:76px; }
        @include brk ($m){ top:76px; }
        .icon {
            width:18px;
            height:18px;
            left:calc(50% - 9px);
            top:calc(50% - 9px);
        }
        svg {
            transition: transform 0.3s;
            transform: scale(0.6);
        }
        .st0{fill:none;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
    }
    &.disabled {
        .attacher-vos-docs {user-select:none;}
        button {cursor: default}
    }
    &:not(.disabled) {
        label, .attacher-vos-docs {
            cursor: pointer;
            &:hover .icon-container {
                background:darken($touche1,15%);
                transform: scale(1);
            }
        }
        .icon-container {
            cursor: pointer;
            &:hover {
                background:darken($touche1,15%);
                transform: scale(1);
            }
        }
        .ul-fichiers {
            li {
                cursor: pointer;
                &:hover {
                    .icon-bin {
                        transform: scale(1);
                        transition: background 0.4s, transform 0.5s;
                        background: desaturate(red, 30%);
                    }
                    p {color:#000;}
                }
            }
            p {cursor: pointer;}
        }
    }
    .ul-fichiers {
        margin-bottom: 15px;
        li {
            display: inline-block;
            width:calc(100% - 45px);
            padding-top: 10px;
            padding-bottom: 10px;
            margin-top: -4px;
            @include brk($t) {width:calc(100% - 10px);}
            &:nth-child(1) {margin-top: 20px;}
            &.ng-enter {
                @for $i from 1 through 50 {
                &:nth-child(#{$i}) { 
                    transition-delay: 0s + (0.04s * $i) - 0.04s;
                }
                }
                transition: opacity 0.7s;
                opacity: 0;
                &.ng-enter-active {
                    opacity: 1;
                }
            }
        }
        p {
            padding: 0;
            text-align: left;
            transition: color 0.5s;
            padding: 0px;
            font-size: 12px;
            letter-spacing: 0px;
            font-weight: $light;
            line-height: 1.6;
            user-select:none;
        }
        .fichier-nom {
            float: left;
            width: calc(100% - 130px);
            overflow: hidden;
            position: relative;
        }
        .fichier-taille { float: right;}
        button {
            float: right;
            padding: 0 0 0 20px;
            .icon-bin {
                transform: scale(0.8);
                margin-top: -3px;
                background: #bbb;
                transition: background 0.3s, transform 0.5s;
                width:23px;
                height:24px;
                border-radius:50%;
                @include brk($td) {
                    background: desaturate(red, 30%);
                    transform: scale(0.84);
                }
                svg {transform: scale(0.4);}
                .st0{fill:none;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
            }
        }
    }
}



#progress_bar {
    width:calc(100% - 50px);
    height:1px;
    background:transparentize(#000, 0.9);
    margin-bottom: 23px;
    transition: width 1.5s;
    @include brk($t) {width:calc(100% - 10px);}
    .actual-progress {
        height:100%;
        background:#2a8128;
        width:0%;
        transition:width 0.3s;
    }
}






$response_box_height: 50px;
.response_box-container {
    position: relative;
    // overflow: hidden;
    height:$response_box_height;
    padding-top:50px;
    // background: #eee;
    &.ng-enter {
        transition: opacity 1.5s, height 1s; 
        height:0; 
        opacity:0; 
        &.ng-enter-active {
            opacity:1; 
            height:$response_box_height; 
        }
    }
    &.ng-leave {
        transition: opacity 2.5s, height 2.5s; 
        height:$response_box_height; 
        opacity:1; 
        &.ng-leave-active {
            opacity:0; 
            height:0px; 
        }
    }
    .response_box {
        text-align:center;
        display: inline-block;
        position: absolute;
        top:$response_box_height - 10px;
        left:0;
        font-size: 14px;
        width:100%;
        letter-spacing: 0px;
        font-style: italic;
        &.please-wait {color:#333}
        &.success {color:green}
        &.error {color:red}
    }
}





















// TOOLTIP   ---------------------------------

$tooltip_red: mix(red, #b46c6b, 28%);
$tooltip_orange: mix(orange, #dbb36d, 15%);

@keyframes show_tooltip {
    0% {z-index:1; transform: translateY(30px)}
    50% {z-index:1; transform: translateY(-1px)}
    51% {z-index:20}
    100% {z-index:20;  transform: translateY(0px)}
}
@keyframes show_tooltip_triangle {
    0% {transform: translateY(-20px)}
    20% {transform: translateY(-20px)}
    100% {transform: translateY(0px)}
}

form.ng-submitted {
    input.focus-submitted + .tooltip,
    div.container.select.focus-submitted .tooltip,
    div.radio-container.focus-submitted .tooltip,
    div#gdpr_container.focus-submitted .tooltip,
    textarea.focus-submitted + .tooltip {display:inline-block;}
}

form .tooltip {
    position: absolute;
    top:-45px;
    left:19px;
    will-change: transform, opacity;
    box-shadow: 0px 0px 4px 1px transparentize(#000, 0.91) !important;
    transform: translate3d(0,0,0);
    display:none;
    animation: show_tooltip 0.25s;
    span {
        padding:15px 19px;
        display: inline-block;
        color:#fff;
        font-size: 12px;
        animation: fade 0.2s;
        cursor:default;
        &::first-letter { text-transform: uppercase; }
    }
    >div {
        // border-top-right-radius:2px;
        // border-top-left-radius:2px;
    }
    >div::after {
        content:"";
        transform: translate3d(0,0,0);
        animation: show_tooltip_triangle 0.3s;
        position: absolute;
        left:0;
        bottom:-9px;
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid green;
    }
    .files-missing,
    [ng-message="required"] {
        background: $tooltip_red;
        &::after {border-left-color:$tooltip_red;}
    }
    [ng-message="pattern"] {
        background: $tooltip_orange;
        &::after {border-left-color:$tooltip_orange;}
    }
}





// BACKGROUND CHANGE -----------------------------------

.contact-form form.ng-submitted {
    input.ng-invalid,
    textarea.ng-invalid {
        background:mix($contact_form_background, $tooltip_red, 91%)!important;
    }
    input.ng-invalid-pattern,
    textarea.ng-invalid-pattern {
        background:mix($contact_form_background, $tooltip_orange, 85%)!important;
    }
}












// REQUIRED ICONS ---------------------------------


.page.contact form {
    .container {
        &.input .icon {
            height:100%; 
        }
        .icon {
            position: absolute;
            z-index: 20;
            top:0.5px;
            will-change: opacity, transform;
            &.ng-enter {
                opacity:0;
                transform:scale(0);
                transition: opacity 0.25s 0.05s, transform 0.2s 0.05s;
                &.ng-enter-active {
                    transform:scale(1);
                    opacity:1; 
                }
            }
        }
        .icon-checked {
            width:14px;
            left:19px;
            .st0 {fill:transparentize(green, 0)}
        }
        .icon-circle {
            width:9px;
            left:20px;
            .st0{fill:mix(orange, $tooltip_orange, 90%)}
        }
        .icon-cross {
            width:10px;
            left:19px;
            .st0{
                fill:none;
                stroke:mix(red, $tooltip_red, 60%);
                stroke-width:9;
                stroke-miterlimit:10;
            }
        }
        &.textarea {
            .icon-checked {top: 32.5px;}
            .icon-circle, .icon-cross { top: 31.5px;}
        }
    }

    input {
        &.prefill,
        &.ng-invalid.cross-has-shown,
        &.ng-invalid-pattern.ng-dirty.blured,
        &.ng-invalid-pattern.ng-dirty.blured,
        &.ng-valid.blured.ng-dirty,
        &[disabled="disabled"] {
            padding-left: $field_padding_left;
        }
    }

    textarea {
        &.ng-valid:not(.ng-empty), &.cross-has-shown {
            padding-left: $field_padding_left;
        }
    }

    .not-required-input {
        // input[ng-required="false"].ng-empty {padding-left:20px !important}
        // .tooltip.not-required-input + .icon-checked{display:none!important}
    }

    .not-required-textarea {
        textarea[ng-required="false"] {padding-left:0 !important}
        .icon-checked {display:none!important}
    }

    &.ng-submitted {
        input, textarea {
            &.ng-invalid-pattern {
                padding-left: $field_padding_left;
            }
            &[type="tel"], &[type="email"] {
                &.ng-valid {
                    padding-left: $field_padding_left;
                }
            }
        }
    }
}





















// WHEN FORM IS COMPLETE & SUBMITTED  ->  DISABLED -------------------

$disabled_color: transparentize($touche2, 0.75) !important;

.contact-form form[disabled="disabled"] {
    .container input, .container textarea {
        color: $disabled_color;
        user-select:none;
        cursor: default;
        padding-left: $field_padding_left;
        &:disabled:-webkit-autofill {
            -webkit-text-fill-color: $disabled_color;
        }
    }
}



 



/**************  PLACEHOLDER   ***************/
.contact-form {
    input::placeholder, textarea::placeholder {
        
        font-size: $input_fontsize-desktop;
        font-weight: $normal;
        color: transparentize($touche2, 0.5) !important;
        text-align: left;
        letter-spacing: 0.5px;
        line-height: 1.3;
        // opacity: 0;
        // transition: opacity 0.3s;
        @include brk($td){ font-size: $input_fontsize-tablet; }
        @include brk($m){  font-size: $input_fontsize-mobile; }
    }
    input:focus, textarea:focus {
        &::placeholder { opacity: 0.35!important; }
    }
}













div.gdpr-container {
    width: 100%;
    display:inline-block;
    margin:60px auto 20px !important;
    position: relative;
    @include brk($td) {
        width: 70%;
    }
    @include brk($tp) {
        width: 100%;
    }
    label[for="gdpr"] {
        width: 100%;
        display:inline-block!important;
        // position: absolute;
        // cursor:pointer;
        // top:16px;
        // left:0px;
        
        padding:15px 70px;
    }
    .tooltip.gdpr {
        left:35px;
        top: -55px;
    }
    p.note {
        position: absolute;
        top:0px;
        left:35px;
        user-select:none;
        z-index: 11;
        font-family: $family1;
        // font-weight: $bold;
        @include brk($m) { 
            font-size: 12px;
        }
        a {
            -webkit-text-stroke: #fff;
            text-decoration: underline;
            @include brk($m) { 
                font-size: 12px;
            }
        }
    }
    a:hover {
        color:$touche1;
    }

    input[type="checkbox"] {
        display: none;
        + label {
            .case {
                cursor:pointer;
                width:20px;
                height:20px;
                border-radius:50%;
                text-align: center;
                position: absolute;
                left:0px;
                top:3px;
                border:1px solid transparentize(#fff, 0);
                padding:6.5px;
                // padding:4px;
                background:#fff;
                @include brk($m) {
                    top:0px;
                }

            }
            .case span {
                background: #fff;
                display: block;
                height:100%;
                transition: opacity 0.05s;
                border-radius:50%;
                opacity: 0;
            }
        }
        &:checked + label {
            // color: #fff;
            .case {background:$touche1;}
            .case span { opacity:1; 
                // background:#360fff; 
            }
        }
        // &:hover + label {color: $white;}
    }
}
