$ft: $t + 0px;
$fm: $m + 100px;



footer {
    overflow-y: hidden;
    background: $footer-color;
    margin-top: -5px;
    z-index: 10;
    #footer_top {
        background:$footer-color;
        padding:60px 0 40px;
        border-top: 1px solid transparentize(#000, 0.92);
        @include brk($ft) {
            padding:45px 0 35px;
        }
        @include brk($fm) {
            padding:10px 0 10px;
            text-align: center;
        }
    }
    #footer_bottom {
        .content {
            padding: 13px 0 5px;
            border-top: 1px solid transparentize(#000, 0.92);
            @include brk($fm) {
                 padding: 10px 0 13px;                
            }
        }
        @include brk($fm) {             
            .banner-content { padding:0; }
        }
    }
    .banner-content {
        @include brk($ft) { padding: 0 6%; }
        @include brk($fm) { padding: 0 6%; }
    }
    .titre-section {
        color:$touche2;
        font-size: 20px;
        font-weight: $bold;
        letter-spacing: 0.5px;
        margin-bottom: 15px;
        user-select:none;
        // text-transform: uppercase;
        @include brk($fm) {
            margin-bottom: 8px;
        }
    }
}





// LOGO ---------------------------------------

footer .logo-container {
    height:100px;
    width: 300px;
    float: left;
    @include brk($ft) {
        margin: 0 auto;
        float: none;
        display: none;
    }
    @include brk($fm) {
        display: inline-block;
        width: 140px;
        margin-bottom: 10px;
    }
    .logo-image {
        height:100%;
        width: 160px;
        background-size:contain;
        position: absolute;
        left:0;
        top:0;        
        .st0{fill:#239BD7;}
        .st1{fill:#232A53;}
        .st2{opacity:0;fill:#9D9D9C;}
        .st3{font-family:'ProximaNova-Regular';}
        .st4{font-size:7.1804px;}
        @include brk($fm) {
            width: 140px;
            left:calc(50% - 70px);
        }
    }
}






// MENU ---------------------------------------------

footer .menu-footer {
    float: left;
    width: 25%;
    @include brk($ft) {
        width: 35%;
    }
    @include brk($fm) {
        margin-bottom: 35px;
        float: none;
        display: none;
    }
    .titre-section {
        span {
            color:$touche2;
        }
    }
    >ul li {
        &:first-child {
            a { border-top:none; }
        }
        >a:hover, >a:focus {
            color:$touche1;
        }
    }
    >ul>li a {
        font-size: 16px;
        font-weight: $normal;
        padding:6px 0px;
        width:200px;
        color:#0f205a;
        border-top: 1px solid transparentize(#fff, 0.9);
        &::before {
            content:"›";
            margin-right: 5px;
        }
        @include brk($fm) {
            width: 100%;
        }
    }
    &.menu1 {
        >ul>li>ul {display:none;}
        >ul>li:first-child {
            display: none;
        }
    }
    &.menu2 {
        >ul>li>a {
            display: none;
        }
    }
}










// COORDONNES -----------------------------------------

footer .bloc-coordonnees {
    width:22%;
    float: right;
    @include brk($ft) { width: 25%; }
    @include brk($fm) { width:100%;  margin-bottom:24px;}
    p, a {
        font-size: 15px;
        color:#0f205a;
        line-height: 2;
        font-weight: $normal;
        font-family: $family1;
        user-select: text;
        span {
            font-weight: $bold;
        }
        @include brk($fm) { 
            // font-size: 13.5px;
            // letter-spacing: 0;
        }
    }
    span {
        display:block;
        color:#0f205a;
        font-weight: $bold;
        a {
            padding-left:5px;
            -webkit-text-stroke: $touche1;
            text-decoration: underline;
        }

        &[itemprop="address"] {
            p {
                color:$touche2;
            }
        }
    }
    a {position: relative;}
    a:not(.tel-container):hover, &:not(.tel-container):focus {
        color:$touche1;
        
    }

    .tel-container {
        background:$touche1;
        height:40px;
        display: inline-block;
        width: 180px;
        border-radius: 20px;
        margin-top: 35px;
        text-decoration: none;
        -webkit-text-stroke: $touche2;
        text-align: left!important;
        @include brk($fm) { 
            margin-top: 25px;
        }
        .icon-container {
            width:40px;
            height:40px;
            border-radius: 20px;
            background: $touche2;
            position: absolute;
            top:0;
            left:0;
            svg {
                transform: scale(0.4);
            }
            .st0 {
                fill:#fff;
            }
        }
        p {
            color:#fff;
            font-size: 16.5px!important;
            padding-left:58px!important;
            padding-top: 5px!important;
            font-weight: $bold;
            letter-spacing: 1px;
            position: relative;
            text-transform: uppercase;
            text-decoration: none !important;
            -webkit-text-stroke: $touche2;
        }
        &:hover {
            background:$touche2;
            .icon-container {
                background:$touche1;
            }
        }
    }
}

















// SOCIAL ICONS  -----------------------------------------

footer .social-container {
    position: absolute;
    right:0;
    top:20px;
    @include brk($ft) {
        right:0%;
    }
    @include brk($fm) {
        float:none;
        position: relative;
        text-align: center;
        display: block;
        top:auto;
        margin: 15px auto 10px;
    }
    .icon-container {
        margin-left:10px;
        .icon {
            width:30px;
            height:30px;
            background:#0d1221;
            border-radius:50%;
            &.facebook svg {
                transform: scale(1.2);
            }
            &.linkedin svg {
                transform: scale(1) translate3d(0px, 0px, 0);
            }
        }
        .st0 {fill:#fff}
        .st1 {fill:$touche1; transition: fill 0.3s}
        &:hover, &:focus {
            .icon{background:darken($touche1, 0%);}
            // .st0 {fill:darken(#0d1221, 25%)}
            // .st1 {fill:lighten(#fff, 20%);}
        }
    }
}










// COPYRIGHT LINE ----------------------------------

footer .copyright-line {
    display:inline-block;
    text-align:center;
    margin-left: -7px;
    width:100%;
    @include brk($ft) {
        width: auto;
    }
    @include brk($fm) {
        width:100%;
        text-align: center;
        margin-left: 0px;
    }
    p, a, span {
        font-size: 9.5px;
        font-weight: $normal;
        color:#0f205a;
        text-transform: uppercase;
        letter-spacing:0.6px;
        transition: color 0.6s;
        @include brk($fm) {
            letter-spacing:0.2px;
        }
    }
    a {
        padding: 15px 8px;
        text-decoration: none;
        position: relative;
        cursor:pointer;
        @include brk($ft) {
            padding: 15px 8px;
        }
        @include brk($fm) {
            padding: 4px 10px;
            &:first-child, &:last-child {
                // display: block;
            }
        }
        &:hover, &:focus {color:$touche1;}
        &:first-child {
            border-bottom:none;
            &:before {display:none;}
        }
        &:nth-last-child(1):before {
            @include brk($fm) {
                display: none;
            }
        }
        &::before {
            content:"";
            display: inline-block;
            position: absolute;
            top:18px;
            left:-2px;
            width:1px;
            height:10px;
            background:transparentize(#0f205a, 0.8);
            @include brk($fm){display:none;}
        }
    }
    p {
        display:inline;
    }
}





