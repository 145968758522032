



.page.type {
    background:#fff;
}




// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 200px;
$page_wrapper-bannerTop-laptop: 200px;
$page_wrapper-bannerTop-tablet: 200px;
$page_wrapper-bannerTop-mobile: 200px;



.page.type .banner.top {
    background:$footer-color;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.page.type .banner.top .bloc-container {
    transition: line-height 1s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}









.page.type {
    .banner.medium {
        background: #ffffff;
        min-height: 650px;
        padding:0px 0 0px;
        @include brk($t) {
            padding:0px 0 0px;
        }
        .top-image-container {
            position: absolute;
            top:0;
            left:0;
            right:calc(50% + 355px);
            // width: 31%;
            height:835px;
            @include brk($t) {
                display: none;
            }
        }
        >.banner-content {
            // padding:0;
            // max-width: none;
        }
    }
}



.page.type .col-left {
    padding:190px 0 70px;
    position: relative;
    text-align: right;
    width:280px;
    min-height: 970px;
    @include brk($t) {
        padding:70px 0 50px;
        width:100%;
        text-align: left;
        min-height: 220px;
    }
    .sur-titre {
        font-size: 11px;
        font-weight: $bold;
        font-family: $family1;
        color:$touche1;
        animation: fade 2.5s;
        text-transform: uppercase;
        letter-spacing: 4px;
        position: relative;
        z-index: 4;
        user-select:none;
        margin-bottom: 18px;
        @include brk($t) { 
        }
        @include brk($m) { 
            font-size: 9px; 
            margin-bottom: 12px;
        }
    }
    .titre {
        font-size:50px;
        font-weight: $thin;
        font-family: $family1;
        color:$touche2;
        animation: fade 2s;
        line-height: 1.1;
        letter-spacing: 0.5px;
        position: relative;
        text-transform: uppercase;
        z-index: 4;
        user-select:none;
        margin-bottom: 40px;
        @include brk($t) { 
            font-size: 40px; 
            margin-bottom: 10px;
            br {display:none}
        }
        @include brk($m) { font-size: 30px; }
    }
    .trait {
        background:$touche1;
        height:1px;
        width: 108px;
        display: inline-block;
        @include brk($t) { 
            width: 50px;
        }
    }
    
}




.page.type .banner.medium .col-right {
    padding:210px 0 70px;
    width:calc(100% - 400px);
    @include brk($t) {
        width:100%;
        padding:0px 0 70px;
    }
    @include brk($m) {
        padding:0px 0 0px;
    }
    .banner-content {
        padding-left:0;
        padding-right:0;
    }
}





.page.type .banner.vignettes, 
.page.traitement .banner.vignettes {
    background: #fff;
    padding-top:85px;
    margin-bottom:130px;
    &.traitement {
        margin-top: 150px;
    }
    @include brk($m) {
        padding-top:0px;
    }
    .bloc-titre-section {
        background: #fff;
        top: -70px;
        .icon-container {
            .st0 {stroke:$touche1;}
        }
        .titre-section {
            color:$touche2;
        }
    }
    .ombre {
        &.side {
            height:130%!important;
            @include brk($m) {
                &.right {height:100%!important;}
            }
        }
    }
    .cta-container {
        @include brk($t){
            padding:0 4%;
        }
    }
}




