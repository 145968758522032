/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x                       : 14px !default;
$hamburger-padding-y                       : 14px !default;
$hamburger-layer-width                     : 18px !default;
$hamburger-layer-height                    : 2px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : #fff !default;
$hamburger-layer-border-radius             : 0px !default;
$hamburger-hover-opacity                   : 0.9 !default;
$hamburger-hover-transition-duration       : 0.1s !default;
$hamburger-hover-transition-timing-function: linear !default;
$hamburger-animation-transition-duration   : 0.1s !default;

$hamburger-close-layer-color               : #fff !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  squeeze,
) !default;

// Base Hamburger (We need this)
// ==================================================
@import "01_base";

// Hamburger types
// ==================================================
@import "02_squeeze";

