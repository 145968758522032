



.page.traitement {
    background:#fff;
}




// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 465px;
$page_wrapper-bannerTop-laptop: 450px;
$page_wrapper-bannerTop-tablet: 320px;
$page_wrapper-bannerTop-mobile: 320px;



.page.traitement .banner.top {
    background: darken(#0f205a, 5%);
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.page.traitement .banner.top .bloc-container {
    transition: line-height 1s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}





.page.traitement .banner.top {
    position: relative;
    .image {
        background-position: 50% 50%;
        filter: brightness(80%);
        // filter: saturate(0%);
        
        &::after {
            @extend .overlay;
            content:"";
            display: none;
            background: transparentize($touche2, 0.3);
        }
        @include brk($m) {
            background-position: 50% 50%;
            &::after {
                display: inline-block;
            }
        }
    }
    .bloc {
        padding: 20px 0 0 0;
        @include brk($t) {
            padding: 20px 0 0 0;
        }
        @include brk($m) {
            padding: 20px 0 0 0;
            text-align: center;
        }
    }
    .sur-titre {
        font-size: 12.5px;
        font-weight: $normal;
        font-family: $family1;
        color: #fff;
        animation: fade 2.5s;
        text-transform: uppercase;
        letter-spacing: 3px;
        position: relative;
        z-index: 4;
        user-select:none;
        margin-bottom: 18px;
        @include brk($t) { 
        }
        @include brk($m) { 
            font-size: 9px; 
            margin-bottom: 12px;
        }
    }
    .titre {
        font-size:52.5px;
        font-weight: $bold;
        font-family: $family1;
        color:#fff;
        animation: fade 2s;
        line-height: 1.1;
        letter-spacing: 4px;
        position: relative;
        text-transform: uppercase;
        z-index: 4;
        user-select:none;
        @include brk($t) { font-size: 40px; }
        @include brk($m) { font-size: 25px; }
    }
    .icon-page {
        position: absolute;
        width: 180px;
        height: 180px;
        bottom:-111px;
        right:0;
        z-index: 20;
        animation: fade 1s;
        .icon {
            background-size:contain;
        }
    }
    
}






.page.traitement {
    .banner.medium {
        background: #ffffff;
        min-height: 400px;
        padding:0px 0 0px;
        @include brk($t) {
            padding:0px 0 0px;
        }
        >.banner-content {
            padding:0;
            max-width: none;
        }
        .col-right {
            width:calc(100% - 345px);
            @include brk($t) {
                width:100%;
            }
        }
        .col-left {
            width:345px;
            @include brk($t) {
                width:100%;
            }
        }

    }
}

















