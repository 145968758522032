



#header_behind {
    height:$header-height;
    background: #fff;
    position: relative;
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
    &.page {
        background: #fff;
    }
}


#header {
    height:$header-height;
    position:fixed;
    top:0px;
    left:0px; 
    z-index: 99;
    background:$white;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.35);
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
    .background {
        position: absolute;
        left:0;
        top:0;
        width:100%;
        display: inline-block;
        height:$header-height;
        @include brk($t) {height:$header-height-tablet;}
        @include brk($m) {height:$header-height-mobile;}
    }
    .banner-content {
        height: $header-height;
        margin: 0 auto;
        @include brk($menu-large) {
            margin:0; 
            padding:0 40px;
            transition: 0.5s;
        }
        @include brk($t) {
            height:$header-height-tablet;
            padding:0 0 0 6%;
        }
        @include brk($m) {height:$header-height-mobile;}
    }
}







#header .logo-container {
    float: left;
    width: 140px;
    height: $header-height;
    position: relative;
    transition: box-shadow 0.3s;
    display: inline-block;
    // box-shadow: 0px 5px 43px 0px rgba(0, 0, 0, 0.2);
    // &:hover {
    //     box-shadow: 0px 5px 43px 0px rgba(0, 0, 0, 0.26);
    // }
    @include brk($t) {
        height: $header-height-tablet;
        width: 110px;
    }
    @include brk($m) {
        width: 100px;
        height: $header-height-mobile;
    }
    .logo-image {
        height: 100%;
        width: 100%;
        // background-image: url('../assets/images/logo.png');
        // background-image: url('../assets/images/logo.svg');
        // background-size:contain;
        display: inline-block;
        position: absolute;
        top:0px;
        left:-2px;
        .st0{fill:#239BD7;}
        .st1{fill:#232A53;}
        .st2{opacity:0;fill:#9D9D9C;}
        .st3{font-family:'ProximaNova-Regular';}
        .st4{font-size:7.1804px;}
        @include brk($t) {
            left:calc(-6% + 0px);
        }
        @include brk($m) {
            left:-5px;
        }
    }
    .logo-texte {
        font-size: 11px;
        float: left;
        display: inline-block;
        width:190px;
        color:#2b6698;
        margin-left: 20px;
        margin-top: 45px;
        line-height: 1.4;
        font-weight: $normal;
        letter-spacing: 0px;
        border-left:1px solid transparentize(#000, 0.9);
        padding: 10px 0px 10px 17px;
        user-select:none;
        @include brk($t) {
            display: none;
        }
    }
}







#header .btn-container {
    position: absolute;
    height: 58px;
    bottom:-58px;
    right:0;
    width:100%;
    @include brk($t){
        height:0;
    }
    @include brk($tp - 1px){
        display:none!important;
    }
    .btn-01 {
        float: right;
        height:60px;
        margin-left: 5px!important;
        box-shadow: inset 0px 33px 33px -30px rgba(0, 0, 0, 0.4);
        @include brk($t){
            transform: rotate(-90deg);
            position: absolute;
            right:-96px;
            top:150px;
        }
        p {
            font-size: 12.5px;
            font-weight: $normal;
            letter-spacing: 2px;
            text-transform: uppercase;
            padding-top:21px;
            @include brk($t){
                padding-right: 70px;
            }
        }
        &.devis {
            background:#0f205a;
                transition: background 1s;
            .icon {
                transition: background 1s;
                background: $touche1;
                width:30px;
                height:30px;
                top:15px;
                border-radius:15px;
                font-size: 22px;
                padding-top:3px;
                padding-left:3px;
                right:18px;
                svg {transform: scale(0.65);}
                .st0{fill:#fff;}
                &::before {
                    display: none;
                }
            }
            span {
                display: block;
                font-weight: $bold;
            }
        }
        &:hover {
            background:darken($touche2, 20%);
            .icon {
                // background:$touche2;
            }
        }
    }
}














#nav_desktop {
    display:inline-block;
    float:right;
    height:80px;
    clear:right;
    border-top: 1px solid transparentize(#000, 0.9);
    @include brk($t) {display:none;}
    >ul {
        list-style-type:none;
        height:100%;
        user-select: none;
    }
    >ul>li {
        display:inline-block;
        margin-left:-5px;
        height:80px;
        line-height: 80px;
        user-select: none;
        position: relative;
        &:hover, &.current-menu-item, &.current-menu-ancestor, &.current_page_ancestor, &.selected {
            >a {color:transparentize(#222d62,0);}
        }
        &:last-child {
            margin-right: -19px;
        }
    }
    >ul>li>a {
        display: block;
        padding: 30px 22px 20px;
        font-size: 12px;
        letter-spacing: 1px;
        color: transparentize(#222d62,0.1);
        font-weight: $bold;
        text-transform: uppercase;
        height:100%;
        user-select: none;
        &:hover {color:transparentize(#222d62,0);} 
    }






    // SOULIGNÉ ----------------------- 

    >ul>li:not(.btn)>a::before {
        content:"";
        width:0px;
        opacity: 0;
        transition: width 0.2s, opacity 0.5s, background 0.3s;
        height:4px;
        position: absolute;
        display: inline-block;
        background:$touche1;
        left:23px;
        bottom:0px;
    }
    >ul>li:not(.btn):hover >a::before,
    >ul>li:not(.btn).current-menu-ancestor >a::before,
    >ul>li:not(.btn).current-menu-parent >a::before,
    >ul>li:not(.btn).current-menu-item >a::before,
    >ul>li.selected >a::before {
        opacity: 1;
        width:calc(100% - 45px);
    }


    // HOME ICON ---------------------------------

    >ul>li:first-child {
        position:relative; width:57px;
        a::after {
            content:"";
            width:20px;
            height: 20px;
            display: inline-block;
            position:absolute;
            top:28px;
            left:5px;
            cursor:pointer!important;
            background:url('../assets/images/icon-home3.svg');
        }
        a {color: rgba(0,0,0,0);}
        >a::before {
            left:5px;
        }
        &:hover >a::before,
        &.current-menu-item >a::before {
            width:calc(100% - 36px)!important;
        }
    }


    // SEPARATION VERTICALE -----------------

    >ul>li{
        &::before {
            content:"";
            width:1px;
            height:30px;
            position: absolute;
            display: inline-block;
            background:transparentize(#000, 0.9);
            top:24px;
            left:0px;
        }
        &:first-child::before {display:none;}
        &:nth-child(2)::before {left:-5px;}
    }



    // FLECHE PARENT -----------------

    >ul>li.menu-item-has-children {
        position: relative;
        >a {padding-right:30px;}
        >a::after {
            content:"";
            width:7px;
            height:8px;
            display: inline-block;
            position:absolute;
            top:37px;
            right:17px;
            cursor:pointer!important;
            background:url('../assets/images/parent-arrow.svg');
        }
        &.current-menu-item >a::before {
            width:calc(100% - 36px)!important;
        }
    }


}






//  LEVEL 2   ------------------------------------


#nav_desktop >ul>li {
    &:hover ul {
        display:block;
    }
    >ul {
        position:absolute;
        left:0px;
        top:$header-height;
        margin-left: -22px;
        margin-top: -44px;
        display: none;
        z-index: 10;
        padding: 0 60px 60px 40px;
    }
    >ul>li {
        background:#fff;
        display:inline-block;
        float:left;
        line-height: 1;
        border-top: 1px solid transparentize(#000, 0.9);
        transition: border 1s;
        box-shadow: 2px 4px 14px 0px transparentize(#000, 0.91);
        position: relative;
        &:first-child {
            border:none!important;
            a {
                border-top: 3px solid transparentize($touche1, 0)!important;
            }
        }
        &.current-menu-item, &:hover {
            background:lighten(#000, 95%);
            &, & + li {
                transition: border 0.5s;
                border-top: 1px solid transparentize(#000, 1);
            }
            a::after {
                transform: translate3d(1px,0,0);
            }
        }
        &.current-menu-item>a {
            color:$touche2;
            font-weight: $bold;
            &::after {display:none}
        }
        >a {
            line-height: 1;
            font-size:13px;
            font-weight: $bold;
            color: $touche3;
            width:290px;
            padding: 26px 30px;
            text-transform: uppercase;
            letter-spacing: 0.8px;
            &::after {
                content:"›";                
                font-weight: $normal;
                font-size: 23px;
                position: absolute;
                display: inline-block;
                transition: transform 0.15s;
                top:21px;
                right:25px;
                opacity: 0;
            }
            &:hover {
                &::after {
                    opacity: 1;
                    transform: translate3d(1px,0,0);
                }
            }
        }
    }
    // &.current_page_ancestor>ul {display:inline-block;}
    >ul>li.menu-item-has-children.current-menu-item ul{display:block}
    >ul>li>ul {
        display: block;
        position:absolute;
        top:100px;
        left:0px;
        height:100px;
        display:none!important;
        background:$level3; width:40000px;
        >li {
            display:inline-block;
            float:left;
            height:100%;
            &:first-child a {padding-left:0}
            a {
                padding-top:42px;
                display:inline-block;
                font-size:13px;
                font-weight: 700;
                color:#333333;
                &:hover {color:$dark-grey;}
            }
        }
    }
}



















// DESKTOP MENU SECONDAIRE  ---------------------------------


#nav_desktop_2 {
    display:inline-block;
    height:40px;
    float: right;
    @include brk($t) {display:none;}
    a.btn {
        display: inline-block;
        float: right;
        position: relative;
        padding-left: 25px;
        margin-left: 30px;
        .icon-container {
            position: absolute;
            top:7px;
            left:5px;
            height:20px;
            width:20px;
            .st0 {fill: $touche1;}
        }
        p {
            padding:9px 7px;
            font-size: 11.5px;
            color:$touche2;
            text-transform: uppercase;
            &::after {
                content:"";
                display: inline-block;
                width: 0;
                opacity: 0;
                height:2px;
                position: absolute;
                left:7px;
                bottom:5px;
                background:$touche1;
                transition: width 0.1s, opacity 1.2s;
            }
        }
        &::after {
            content:"";
            display: inline-block;
            width: 1px;
            height:23px;
            position: absolute;
            right:-15px;
            bottom:7px;
            background:transparentize(#000, 0.9);
            transition: width 0.1s, opacity 1.2s;
        }
        &.tel {
            .icon-container svg {
                transform: scale(0.7);
            }
        }
        &:hover {
            p::after {
                width: calc(100% - 13px);
                opacity: 0.85;
            }
        }
    }

}



































// WPML -----------------------------------------------------

.wpml-container {
    float: right;
    height: 40px;
    margin-left: 19px;
    &.home {
        .wpml-ls-legacy-dropdown {
            >ul>li>a {
                // color: transparentize(#fff, 0.1);
            }
        }
    }
}


#header.page .wpml-ls-legacy-dropdown,
#header #lang_sel {
    >ul>li>a {
        // color:#fff !important;
        span::after {
            // background: url('../assets/images/wpml_down-white.svg')
        }
    }
}


#header .wpml-ls-legacy-dropdown,
#header #lang_sel {
    height: 40px;
    position: relative; 
    display: inline-block;
    float:right;
    @include brk($menu-off) {
        display: none;
    }
    >ul {
        height: 40px;
        >li { outline: 0 none !important; }
    }
    a {
        font-size: 11.5px;
        font-weight: $normal;
        color: transparentize($touche2, 0.1);
        font-family: $family1;
        text-transform: uppercase;
    }
    >ul:hover >ul>li>a {
        color: transparentize($touche2, 0);
    }
    >ul>li>a {
        height: 40px;
        padding:  10px 16px 10px 12px;
        span {
            position: relative;
            &::after {
                content:"";
                width:6px;
                height:6px;
                position:absolute;
                top:7px;
                right:-13px;
                background-size:contain;
                background-repeat: no-repeat;
                background-image: url('../assets/images/wpml_down.svg')
            }
        }
    }
    ul:hover, ul:hover ul {
        visibility:visible !important;
    }
    >ul>li>ul {
        visibility:hidden;
        position:absolute;
        // height:100%;
        top: 35px;
        left:-13px;
        z-index: 100;
        padding: 0 20px 20px;
    }
    >ul>li>ul>li {
        height: 40px; 
        width: 40px;
        background:#eeeeee;
        border-radius:50%;
        margin-bottom: 9px;
        &:hover {background:$white;}
    }
    >ul>li>ul>li>a {
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        height:100%;
        text-align: center;
        padding-top:11px;
        border-radius:50%;
    }
}















// LOADING BAR -------------------------------

#loading_bar {
    height:1.5px;
    width: 0%;
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 10000;
    transition: width 2s;
    &.home {
        background: transparentize(#000, 0.95);
    }
    &.page {
        height:1px;
        background: transparentize(#fff, 0.4);
    }
}

