



html {
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    background:$footer-color;
}


#body_wrapper {
    background:$footer-color;
    width:100%;
    min-height:100%;
    overflow-y: hidden;
    position: relative;
}

#main {
    position:relative;
    background:$main-background-color;
    overflow:hidden;
    z-index: 10;
    margin-top: -5px;
}


.page-overlay{
    position:fixed;
    top:$header-height;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:200;
    height:100%;
    text-indent:-9999px;
    overflow:hidden;
    display:none;
    background: #000;
    opacity:0;
    transition: opacity 0.3s;
    will-change: opacity;
    // @include brk($t) {top:$header-height-tablet;}
    // @include brk($m) {top:$header-height-mobile;}
    // span {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     margin: -62px 0 0 -62px;
    //     width: 125px;
    //     height: 125px;
    //     background: url(images/preload_v3@2x.gif);
    //     background-size:cover;
    // }
}





