


.single {
	background:#fbfafa;
}




// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 400px;
$page_wrapper-bannerTop-laptop: 360px;
$page_wrapper-bannerTop-tablet: 219px;
$page_wrapper-bannerTop-mobile: 219px;



.single .banner.top {
    background:$footer-color;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    height:auto !important;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.single .banner.top .bloc-container {
    transition: line-height 1s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}





.single .banner.top {
	transition:height 1s;
    .image {
        background-position:50% 0%;
        background-image:url('../assets/images/2600x800_actus.jpg')
    }
	.banner-content {
		@include brk($t) {
			padding-left:0;
		}
	}
	.bloc {
		padding-bottom:60px;
	}
	.titre {
        font-size:45px;
        font-weight: $semi-bold;
		text-align: left;
        animation: fade 2s;
        line-height: 1.1;
        letter-spacing: 0.5px;
        color:#00528a;
        position: relative;
        z-index: 4;
        user-select:none;
        @include brk($t) { font-size: 40px; }
        @include brk($m) { font-size: 30px; }
    }
}






$sub_banner-desktop: 83px;
$sub_banner-tablet: 74px;
$sub_banner-mobile: 70px;


.single .banner.top .sub-banner{
	background:transparentize(#fbfafa, 0.4);
	.content {
		position: relative;
		height:$sub_banner-desktop;
		@include brk($t) { height:$sub_banner-tablet; }
		@include brk($m) { height:$sub_banner-mobile; }
	}
}











.single .banner.medium {
	background:#fff;
	margin-top:-4px;
	padding:182px 0 70px;
	.col-left {
	    display: inline-block;
	    position: relative;
	    width: 30%;
	    float: left;
	    @include brk($td) {
			width: 100%;
			display: none;
	    }
	    .sur-titre {
            font-size: 10px;
            color:$touche1;
            text-transform: uppercase;
            letter-spacing: 3px;
            margin-bottom: 30px;
        }
    	.titre-type {
    		font-size: 47.5px;
            font-weight: $thin;
            font-family: $family1;
            color: $touche2;
            line-height: 1.1;
            text-transform: uppercase;
            letter-spacing: 0px;
            margin-top: 5px;
            margin-bottom: 30px;
            @include brk($td) {
                font-size: 40px;
            }
            @include brk($m) {
                font-size: 32.5px;
            }
    	}
    	.lien {
    		// animation: fade 2s;
    		span {
    			font-size: 24px;
    			font-weight: $bold;
    			color:$touche1;
    			margin-left: 20px;			
    			display:inline-block;
    			transform: translateY(2px);
    		}
    		font-size: 11px;
    		font-weight: $bold;
    		letter-spacing: 1px;
    		color:#0f205a;
    		text-transform: uppercase;
    		padding: 5px 0;
            &:hover {
                &, & span {
                    color:$touche1;
                }
                span {
                    color:$touche2;
                }
            }
    	}
	}
	.col-right {
	    display: inline-block;
	    position: relative;
	    width: 67%;
	    float: right;
	    padding-left:30px;
	    @include brk($td) {
			width: 100%;
	    }
	    @include brk($tp) {
			padding-left:0px;
	    }
	}
	.fond-illustration {
        animation: fade 2s;
        position: absolute;
        left:0;
        bottom:0;
        width:65%;
        height:1050px;
        background-size:contain;
        background-position:0% 100%;
        @include brk($td){display:none;}
    }

	.type {
		color:$touche1;
		font-size: 62.5px;
		font-weight: $bold;
		margin-bottom: 30px;
		letter-spacing: 0;
		@include brk($td) {
			font-size: 52px;
	    }
	}
	.btn-retour {
		margin-top: 20px;
		display: block;
		cursor:pointer;
		position: relative;
		animation: fade 1.2s;
		display: none!important;
		@include brk($t) { line-height:$sub_banner-tablet; }
		@include brk($td) {
			margin-top: 0px;
			margin-bottom: 40px;
	    }
		p {
			font-size: 12px;
			font-weight: $bold;
			color:#0f205a;
			padding:20px 0;
			text-transform: uppercase;
			letter-spacing: 1px;
			span {
				font-size: 20px;
				font-weight: $bold;
				color:$touche1;
				margin-right: 10px;
				display: inline-block;
				transform: translateY(1px);
			}
			@include brk($m) {
				padding-left: 40px;
			}
		}
		@include brk($m) {
			width: 50px;
			p {display:none;}
		}
		&:hover {
			p, span {
				color:$orange;
			}
		}
	}
	
	.date {
		font-size: 11.5px;
        font-weight: $bold;
		color:#20b196;
		user-select:none;
		// animation: fade 1s;
        text-transform: uppercase;	            
        letter-spacing: 2px;
        margin-bottom: 20px;
        &::after {
        	content:"";
        	display: inline-block;
        	background:#20b196;
        	height:1px;
        	width:8px;
        	margin-left: 5px;
        	transform: translateY(-2.5px);
        }
        &.agenda::after{display:none}
	}

	.post-chapeau {
		margin-bottom: 40px;
		p {
			color:#1599d6;
			font-size: 20px;
			@include brk($td) {
				font-size: 18px;
			}
			@include brk($m) {
				font-size: 18px;
			}
		}
	}

	.post-titre {
		font-size: 32.5px;
		color:#494949;
		font-weight: $semi-bold;
		line-height: 1.2;
		margin-bottom:35px;
		// animation: fade 1s;
		position: relative;
		&::before {
            content:"";
            position: absolute;
            background: $touche1;
            top:8px;
            left:-29px;
            height:calc(100% - 13px);
            width:5px;
            text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.1);
			@include brk($tp) {
				left:1px;
		    }
        }
		@include brk($t) {
			font-size: 30px;
		}
		@include brk($tp) {
			padding-left:30px;
	    }
		@include brk($m) {
			font-size: 22px;
			// line-height: 1.6;
		}
	}
	.flex-chapeau {
		margin-bottom: 35px;
		@include brk($tp) {
			margin-bottom: 25px;
		}
		p {
			font-size: 22.5px;
			font-weight: $light;
			letter-spacing: 0.5px;
			color:$touche1;
			@include brk($t) {
				font-size: 21px;
			}
			@include brk($tp) {
				font-size: 19px;
				line-height: 1.4;
			}
		}
	}
	.post-image, .flex-image {
		width: calc(100%);
		height:314px;
		position: relative;
		margin-bottom: 45px;
		@include brk($t) {
			height:250px;
		}
		@include brk($td) {
			height:228px;
		}
		@include brk($tp) {
			width: calc(100%);
			margin-left: 0px;
		}
		@include brk($m) {
			height:110px;
			margin-bottom: 30px;
		}
		.image {
			animation: fade 1s;
		}
	}
	.post-texte, .flex-texte {
		margin-bottom: 50px;
		@include brk($t) { margin-bottom: 40px; }
		@include brk($m) { margin-bottom: 30px; }
		p, a, span {
		}
		h2, h3, h4 {
			color:$touche1;
			font-weight: $bold;
		}
		h2 {

		}
	}

	.flex-appel {
	    margin-bottom: 65px;
	    border-top:1px solid transparentize(#000, 0.92);
	    border-bottom:1px solid transparentize(#000, 0.92);
	    .banner-content {
	        padding: 10px 0 28px;
	    }
	    .appel-titre {
	        font-size: 23.5px;
	        font-family: $family2;
	        text-transform: uppercase;
	        font-weight: $light;
	        color:$touche2;
	        position: relative;
	        display: inline-block;
	        width: 63%;
	        padding-top:17px;
	        line-height: 1.4;
	        letter-spacing: 0.8px;
	        float: left;
	        @include brk($t) { font-size: 21.5px; width: 100%;}
	        @include brk($t) {
	            margin-bottom: 25px;
	        }
	        @include brk($m) { 
	            font-size: 20px; 
	            margin-bottom: 25px;
	            span {display:block;}
	        }
	    }
	    .btn { 
	        float: right;
	        display: inline-block;
	        position: relative;
	        background:$touche1 !important;
	        border:1px solid transparentize(#000, 0.9);
	        padding: 21px 75px 12px 30px;
	        height:68px;
	        @include brk($t) {
	            float: left;
	        }
	        p {
	            font-size: 12px;
	            text-transform: uppercase;
	            font-weight: $bold;
	            letter-spacing: 1px;
	            line-height: 1;
	            color:#fff;
	            position: relative;
	            display:inline-block;
	        }
	        .icon-download {
	            width:14px;
	            height:14px;
	            margin-left: 23px;
	            margin-right: -15px;
	            transform: translate3d(0,5px,0);
	            // position: absolute;
	            // left:0;
	            // top:23px;
	            display:inline-block;
	            path, rect {fill:#fff; transition: transform 0.3s;}
	            path {transform: translate3d(0,0,0);}
	        }
	        .icon {
	            position: absolute;
	            color:#fff;
	            font-size: 20px;
	            right:22px;
	            top:6px;
	            height:100%;
	            padding-top: 14px;
	            user-select:none;
	        }

	        &:hover {
	            background:$touche2 !important;
	            p {
	                color:#fff;
	            }
	            path, rect {fill:#2e2b26}
	            path {transform: translate3d(0,2px,0);}
	        }
	    }
	}








	.module-partage {
		display:block;
		height: 50px;
		animation: fade 2s;
		text-align:left;
		// position: absolute;
		// top:73px;
		left:0px;
		margin-top: 50px;
		user-select:none;
		// position: fixed;
		// top: $header-height + 83px + 74px;
		// left: calc(50% - 488px);
		@include brk($t) {
			top:auto;
			left: auto;
			position: relative;
			width:250px;
			margin-top: 20px;
			text-align:left;
			height: 65px;
		}
		@include brk($t) {
			margin-top: 0px;
		}
		.icon {
			height: 46px;
			width: 46px;
			margin-right: 13px;
			margin-bottom: 18px;
			border:1px solid #eefbf6;
			cursor: pointer;
			background:transparentize(#229bd7, 0.93);
			border-radius:50%;
			@include brk($t) {
				margin-right: 13px;
			}
			&:hover {
				transition: border 0.3s;
				background:$touche1;
				border:1px solid $touche1;
				svg {
					// transform:scale(1.15);
				}
				.st0 {fill:#fff;}
			}
			svg {transform:scale(1.0); transition: transform 0.8s}
			.st0 {
				// transition: fill 1s;
				fill:$touche1;
			}
		}
	}

	// .titre-type {
	// 	font-size: 32.5px;
 //        font-weight: $bold;
 //        font-family: $family1;
 //        color: #002d42;
 //        // text-transform: uppercase;
 //        letter-spacing: 0px;
 //        margin-bottom: 45px;
 //        @include brk($m) {
 //            font-size: 25px;
 //            margin-bottom: 0px;
 //        }
	// }
}



$li_height: 200px;

.single .banner.list {
	ul {
		li {
			height:$li_height;
			width:100%;
			background:darken(#fff, 1%);
			margin-bottom: 1px;
			box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
			@include brk($t) {
				width:100%;
				float: none!important;
			}
			@include brk($td) {
				background:transparentize(#fff, 0);
			}
			@include brk($m) {
				height:auto;
				margin-bottom: 27px;
				background:transparentize(#fff, 1);
			}
			.image-container {
				width:194px;
				position: relative;
				height:100%;
				float: left;
				// transition: filter 0.3s;
				filter: brightness(98%);
				@include brk($td) {
					filter: brightness(100%);
				}
				@include brk($m) {
					height:137px;
					width:100%;
					float: none!important;
				}
				.image {
					animation: none;
					background-size: cover;
				}
			}
			.textes-container {
				width:calc(100% - 194px);
				height:$li_height;
				line-height: $li_height;
				float: right;
				position: relative;
				@include brk($t) {
					float: none;
					display: inline-block;
				}
				@include brk($tp) {
					padding: 5px 20px;
				}
				@include brk($m) {
					width:100%;
					height:auto;
					line-height: 1;
					float: none!important;
					padding: 25px 20px 25px;
				}
			

				.titre-post {
					font-size: 16px;
					font-weight: $semi-bold;
					color:#002d42;
					padding: 0px 35px 0 25px;
					line-height: 1.35!important;
					@extend .bloc;
					@include brk($td) {
						font-size: 16px;
						line-height: 1.30!important;
					}
					@include brk($tp) {
						padding: 0px 0px;
						font-size: 15.5px;
					}
					@include brk($m) {
						
					}
				}
			}
			&:hover {
				box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
				.image-container {
					filter: brightness(100%);
				}
			}
			@include brk($d) {
				&:hover {
					background:#fff;
				}
			}
		}
	}
}

