


.flexible {
    li:first-child .flex-titre {
        // margin-top: 0!important;
    }
    li:last-child .flex {
        // margin-bottom: 0;
    }
    .banner-content {
        // padding:0;
        // max-width: none;
    }
}



.flex {
    display: inline-block;
    position: relative;
    width:100%;
    .sur-titre {
        font-size: 10px;
        font-weight: $bold;
        color:$touche1;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        line-height: 1.25;
        margin-bottom: 20px;
    }
    .titre {
        font-size: 30px;
        line-height: 1;
        font-weight: $bold;
        letter-spacing: 0px;
        color:$touche2;
        @include brk ($tp) { font-size: 26px; }
        @include brk ($m) { font-size: 22px; }
    }
    .chapeau p {
        font-size: 22.5px;
        font-weight: $normal;
        // font-style: italic;
        font-family: $family1;
        line-height: 1.5;
        color:$touche1;
        margin-bottom: 20px;
        position: relative;
        clear: both;
        // padding-left:20px;
        // &:before {
        //     content:"";
        //     display: inline-block;
        //     background:#625538;
        //     width:14px;
        //     height:1px;
        //     position: absolute;
        //     left:0px;
        //     top:20px;
        // }
        @include brk($m) {
            font-size: 18px;
            padding-left:0px;
            margin-bottom: 10px;
            &:before {display:none}
        }

    }
    .lire-la-suite {
        margin-top: 20px;
        color:#fff;
        border-radius: 10px;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 2.5px;
        font-weight: $bold;
        padding:20px 30px;
    }
}








.flex-grandtitre-chapeau {
    margin-top: 0px;    
    margin-bottom: 10px;
    .grand-titre {
        font-size: 30px;
        font-weight: $bold;
        line-height: 1.2;
        color:$touche2 !important;
        padding-bottom:15px;
        border-bottom: 1px solid transparentize(#000, 0.9);
        margin-bottom: 15px;
        @include brk($t) {
            font-size: 26px;
        }
        @include brk($m) {
            font-size: 21px;
        }
    }
    .chapeau p {
        font-size: 22.5px;
        font-weight: $normal;
        @include brk($m) {
            font-size: 18px;
        }
    }
}

.flex-titre-texte {
    margin-bottom: 35px;
}

.flex-texte {margin-bottom: 35px;}

.flex-wysiwyg {
    margin-bottom: 40px;
    ul li {
        color:$touche1;
        font-weight: $bold;
        &:before {
            color:$touche1;
        }
    }
}



.flex-titre-chapeau-texte {
    margin-bottom: 40px;
    .chapeau p {
        font-weight: $bold;
        color:#424242;
        line-height: 1.5;
        // TRAIT ------------------------
        &::after {
            content:"";
            position: absolute;
            top:14px;
            left:-65px;
            height:2px;
            width:35px;
            opacity: 1;
            transition: width 0.1s, opacity 0.5s;
            display: inline-block;
            background: transparentize($touche1, 0.2);
            @include brk($t) {
                display:none;
            }
        }
    }
    .titre p {
        font-size: 25px;
        line-height: 1.3;
        text-transform: uppercase;
        font-weight: $normal;
        letter-spacing: 1px;
        color:$touche2;
        margin-bottom: 35px;
        @include brk ($tp) { font-size: 21px; }
        @include brk ($m) { font-size: 20px; }
    }
}






.flex-intro {
    background:#faf9f9;
    padding:60px 0 30px;
    margin-bottom: 65px;
    .texte {
        p, a, li {
            font-size: 21px;
            color: #010101;
        }
    }
}


.flex-focus {
    margin-top:20px;
    margin-bottom:70px;
    background:transparentize($touche1, 0.965);
    position: relative;
    padding: 30px 60px 35px 47px;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.07);
    @include brk($t) {
        padding: 35px 45px 30px 45px;
    }
    @include brk($m) {
        padding: 30px 35px 25px 35px;
        width:108%;
    }
    .titre {
        font-size: 27px;
        font-weight: $bold;
        font-family: $family1;
        color:$touche3;
        line-height: 1.2;
        letter-spacing: 1px;
        margin-bottom: 15px;
    }
    .sous-titre {
        font-weight: $bold;
    }
    &::before {
        content:"";
        display: inline-block;
        height:100%;
        width:6px;
        position: absolute;
        left:0; top:0;
        background:$touche1;
    }
    ul {
        width: 100%;
        padding: 0 0 0 18px;
        li {
            font-size: 15px;
            font-weight: $normal;
            color:#424242 !important;
            letter-spacing: 1px;
            line-height: 1.6;
            padding: 13px 0px;
            padding-right: 40px;
            margin-bottom: 0;
            border-top:1px solid transparentize(#000, 0.9);
            @include brk($m) {
                padding-right: 20px;
            }
            p {
                padding-bottom: 0;
            }
            &:first-child {border-top:none;}
            &, &:before {
                color: $touche1;
            }
            &::before {
                // display: none;
                top:13px;
                left:-12px;
                font-weight: inherit;
            }
        }
    }
}


.flex-citation {margin-bottom: 60px;}


.flex-image {
    margin-bottom: 45px;
    height:265px;
    @include brk ($tp) { height:235px; }
    @include brk ($m) { 
        // height:150px; 
        margin-bottom: 25px;
    }
    &.has-lightbox .image {
        cursor:pointer;
    }
    img {
        width:100%;
        height:auto;
    }
}





// CITATION  | TEXTE --------------------------------


.flex-citation_texte {
    margin-bottom: 60px;
    .col-citation {
        width: 45%;
        float: left;
        @include brk($tp) {
            width: 100%;
        }
        p {
            color:$touche1;
            font-size: 35px;
            font-family: $family3;
            font-style: italic;
            text-align: right;
            &::before { content:"« "; }
            &::after { content:" »"; }
            @include brk($t) {
                font-size: 30px;
            }
            @include brk($tp) {
                text-align: left;
                font-size: 27px;
                margin-bottom: 30px;
            }
        }
    }
    .col-texte {
        width: 45%;
        float: right;
        @include brk($tp) {
            width: 100%;
        }
    }
}







// TEXTE | TEXTE --------------------------------

.flex-texte_texte {
    margin-bottom: 50px;
    @include brk($m) {
        margin-bottom: 0px;
    }
    .col {
        width:42%;
        &.right {
            float: right;
        }
        @include brk($m) {
            width:100%;
            margin-bottom: 45px;
        }
    }
    .titre-container {
        border-bottom:1px solid transparentize(#000, 0.9);
        margin-bottom: 16px;
        position: relative;
        .icon-container {
            position: absolute;
            top:-5px;
            left:0;
            width:28px;
            height:28px;
            display: inline-block;
            &.problem {
                transform: translate3d(0,1px,0);
                svg {
                    transform: scale(0.9);
                }
            }
        }
        .titre {
            padding-left:40px;
            font-size: 21px;
            font-weight: $light;
            padding-bottom: 25px;
            letter-spacing: 0;
        }
    }
    .chapeau {
        p, a {
            line-height: 1.8;
            font-size: 15px;
            font-weight: $normal;
        }
    }
}



// IMAGE | TEXTE,  STANDARD --------------------------------

.flex-image_texte_standard, .flex-image_texte_standard, {
    margin-bottom: 80px;
    @include brk ($t) {
        margin-bottom:70px;
    }
    .content {
        // padding-top:80px;
        // padding-bottom: 80px;
    }
    .col-image {
        height:305px;
        width:320px;
        overflow: hidden;
        // margin-top: 8px;
        @include brk($tp) {
            height:270px;
            width:290px;
        }
        &.contains-img {
            @include brk ($m) {
                height:auto;
            }
        }
        .image {
            // border-radius:6px;
            animation: fade 2s;
        }
        >img {
            width:100%;
            height:auto;
            // border-radius:8px;
            @include brk ($tp) {
                // width:auto;
                // height:100%;
            }
            @include brk ($tp) {
                width:auto;
                height:100%;
            }
            @include brk ($m) {
                width:100%;
                height:auto;
            }
        }
        .image-lightbox {
            img {
                width:100%;
                visibility: hidden;
                height:auto;
            }
        }
        .bandeau {
            background:$touche1;
            color:#fff;
            z-index: 10;
            position: absolute;
            top:86px;
            right:calc(-75% + 100px);
            width:150%;
            text-align: center;
            padding: 14px 0;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: $bold;
            letter-spacing: 3px;
            transform: rotate(45deg);
            @include brk ($m) {
                top:75px;
            }
        }
        .legende-container {
            background: linear-gradient(to bottom, transparentize(#000, 1), transparentize(#000, 0.2));
            position: absolute;
            left:0;
            right:0;
            bottom:0;
            .legende {
                color: #fff;
                text-transform: uppercase;
                font-family: $family2;
                font-weight: $bold;
                padding:50px 28px 20px;
                font-size: 11px;
                letter-spacing: 2px;
            }
        }
    }
    .col-texte {
        width:calc(100% - 320px);
        padding-left:8%;
        @include brk($tp) {
            width:calc(100% - 290px);
        }
        @include brk($m) {
            padding-left:0%;
        }
        img.icone {
            width:71px;
            height:auto;
            float: left;
            margin-right: 20px;
            transform: translate3d(0,-23px,0);
        }
        ul.liste {
            width:100%;
            li {
                padding: 17px 0;
                margin:0;
                border-bottom: 1px solid transparentize(#625538, 0.85);
                &:before {display:none;}
                .caracteristique, .valeur {
                    display: inline;
                    padding:0;
                }
                .caracteristique {
                    font-weight: $bold;
                }
            }
        }
        .titre {
            font-size: 27.5px;
            letter-spacing: 0;
            display: inline-block;
            position: relative;
            margin-bottom: 20px;
            line-height:1.25;
            @include brk($m) {
                font-size: 23px;
            }
            // TRAIT ------------------------
            &::after {
                content:"";
                height:1px;
                width:70px;
                top:20px;
                position: absolute;
                left: calc(100% + 20px);
                transform: translateY(-5px);
                opacity: 1;
                transition: width 0.1s, opacity 0.5s;
                display: inline-block;
                display: none;
                background: transparentize($touche2, 0.7);
                @include brk($t) {
                    display:none;
                }
            }
        }
        .chapeau p {
            font-size: 19px;
            margin-bottom: 30px;
            @include brk($m) {
                font-size: 18px;
            }
        }
        .btn { 
            background:$touche1;
            border-radius:30px;
            height: 40px;
            position: relative;
            p {
                padding:6px 77px 16px 26px;
                font-size: 14px;
                font-weight: $bold;
                letter-spacing: 0;
                color:$white;
                text-shadow: 0px 4px 0px rgba(95, 107, 120, 0.34)!important;
            }
            .icon {
                font-size: 38px;
                color:#fff;
                width:47px;
                text-align: center;
                font-weight: $normal;
                height:100%;
                position: absolute;
                top:3px;
                line-height: 1;
                right:6px;
                border:none;
                &::before {
                    content:"";
                    height:25px;
                    top:4px;
                    width:1px;
                    background:transparentize(#fff, 0.8);
                    position: absolute;
                    display: inline-block;
                    left:0px;
                }
                span {
                    transition: transform 0.5s;
                    display: inline-block;
                    height:100%;
                    line-height: 1;
                    color:$white;
                    transform: translateY(-5px);
                    text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
                }
            }
        }
        .numero {
            position: absolute;
            top: -166px;
            left: -71px;
            font-size: 225px;
            font-family: $family3;
            font-style: italic;
            color:transparentize(#000, 0.95);
        }
    }
    
    @include brk($tp) {
        .col-image, .col-texte {
            width:100%;
            float: none!important;
        }
        .col-image {
            height:275px;
            margin-bottom:40px;
            .image-lightbox img {
                margin: 0 auto;
                display: block;
                width:60%; 
            }
        }
    }
    @include brk($m) { 
        margin-bottom:55px;
        .col-image {
            height:auto;
            margin-bottom:40px;
            .image {
                width:130%;
                left:-15%;
            }
        }
        ul.caracteristiques li {
            padding: 12px 0 !important;
            &:last-child {
                border-bottom:none;
            }
        }
    }
}

.flex-image_texte {
    .col-texte { float: right; }
}
.flex-texte_image {
    .col-image { float: right; }
}















// IMAGE | TEXTE,  TEXTE | IMAGE --------------------------------

.flex-image_texte, .flex-video_texte, .flex-texte_image, {
    padding-top:90px;
    margin-bottom: 80px;
    @include brk ($t) {
        padding-top:70px;
        margin-bottom:70px;
    }
    @include brk ($m) {
        padding-top:50px;
    }
    .content {
        // padding-top:80px;
        // padding-bottom: 80px;
    }
    .col-image, .col-video {
        height:auto;
        width:505px;
        overflow: hidden;
        // margin-top: 8px;
        @include brk($t) {
            width:50%;
        }
        @include brk($td) {
            width:100%;
            margin-bottom: 50px;
        }
        @include brk($tp) {
            // height:270px;
            // width:290px;
        }
        iframe {
            height: 284px;
            width: 100%;
            @include brk($td) {
                height: 500px;
            }
            @include brk($tp) {
                height: 370px;
            }
            @include brk($m) {
                height: 180px;
            }
        }
        &.contains-img {
            @include brk ($m) {
                height:auto;
            }
        }
        .image {
            // border-radius:6px;
            animation: fade 2s;
        }
        >img {
            width:100%;
            height:auto;
            // border-radius:8px;
            @include brk ($tp) {
                // width:auto;
                // height:100%;
            }
            @include brk ($td) {
                width:auto;
                height:100%;
            }
            @include brk ($m) {
                width:100%;
                height:auto;
            }
        }
        .image-lightbox {
            img {
                width:100%;
                visibility: hidden;
                height:auto;
            }
        }
        .bandeau {
            background:$touche1;
            color:#fff;
            z-index: 10;
            position: absolute;
            top:86px;
            right:calc(-75% + 100px);
            width:150%;
            text-align: center;
            padding: 14px 0;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: $bold;
            letter-spacing: 3px;
            transform: rotate(45deg);
            @include brk ($m) {
                top:75px;
            }
        }
        .legende-container {
            background: linear-gradient(to bottom, transparentize(#000, 1), transparentize(#000, 0.2));
            position: absolute;
            left:0;
            right:0;
            bottom:0;
            .legende {
                color: #fff;
                text-transform: uppercase;
                font-family: $family2;
                font-weight: $bold;
                padding:50px 28px 20px;
                font-size: 11px;
                letter-spacing: 2px;
            }
        }
    }
    .col-texte {
        width:calc(100% - 505px);
        padding-left:6%;
        @include brk($t) {
            width:50%;
        }
        @include brk($td) {
            width:calc(100%);
            padding-left:0%;
        }
        @include brk($m) {
        }
        img.icone {
            width:71px;
            height:auto;
            float: left;
            margin-right: 20px;
            transform: translate3d(0,-23px,0);
        }
        .titre {
            border-bottom:1px solid transparentize(#000, 0.9);
            padding-bottom:30px;
        }
        ul.liste {
            width:100%;
            li {
                padding: 17px 0;
                margin:0;
                border-bottom: 1px solid transparentize(#625538, 0.85);
                &:before {display:none;}
                .caracteristique, .valeur {
                    display: inline;
                    padding:0;
                }
                .caracteristique {
                    font-weight: $bold;
                }
            }
        }
        .btn { 
            background:$touche1;
            border-radius:30px;
            height: 40px;
            position: relative;
            p {
                padding:6px 77px 16px 26px;
                font-size: 14px;
                font-weight: $bold;
                letter-spacing: 0;
                color:$white;
                text-shadow: 0px 4px 0px rgba(95, 107, 120, 0.34)!important;
            }
            .icon {
                font-size: 38px;
                color:#fff;
                width:47px;
                text-align: center;
                font-weight: $normal;
                height:100%;
                position: absolute;
                top:3px;
                line-height: 1;
                right:6px;
                border:none;
                &::before {
                    content:"";
                    height:25px;
                    top:4px;
                    width:1px;
                    background:transparentize(#fff, 0.8);
                    position: absolute;
                    display: inline-block;
                    left:0px;
                }
                span {
                    transition: transform 0.5s;
                    display: inline-block;
                    height:100%;
                    line-height: 1;
                    color:$white;
                    transform: translateY(-5px);
                    text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
                }
            }
        }
        .numero {
            position: absolute;
            top: -166px;
            left: -71px;
            font-size: 225px;
            font-family: $family3;
            font-style: italic;
            color:transparentize(#000, 0.95);
        }
    }
    
    @include brk($tp) {
        .col-image, .col-texte {
            width:100%;
            float: none!important;
        }
        .col-image {
            margin-bottom:40px;
            .image-lightbox img {
                margin: 0 auto;
                display: block;
                width:60%; 
            }
        }
    }
    @include brk($m) { 
        margin-bottom:55px;
        .col-image {
            height:auto;
            margin-bottom:40px;
            .image {
                width:130%;
                left:-15%;
            }
        }
        ul.caracteristiques li {
            padding: 12px 0 !important;
            &:last-child {
                border-bottom:none;
            }
        }
    }
}

.flex-image_texte {
    .col-texte { float: right; }
}
.flex-texte_image {
    .col-image { float: right; }
}












//   IMAGE LARGE | TEXTE --------------------


.flex-imagelarge_texte {
    min-height:675px;
    @include brk($td) {
        min-height:500px;
    }
    @include brk($m) {
        .banner-content {
            padding:0;
        }
    }
    .image {
        @include brk($m) {
            position: relative;
            height:270px;
        }
    }
    .textes-container {
        width:42%;
        background:#fff;
        padding:65px 50px;
        margin-top: 90px;
        margin-bottom: 90px;
        box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.45);
        @include brk($t) {
            width: 50%;
        }
        @include brk($td) {
            width: 100%;
            margin-bottom: 80px;
            text-align: center;
        }
        @include brk($m) {
            margin-top:0px;
            margin-bottom: 0px;
            padding:45px 6%;
        }
        .titre {
            font-size: 30px;
            letter-spacing: 0;
            @include brk($m) {
                font-size: 20px;
            }
        }
        .soustitre {
            font-size: 22.5px;
            color:$touche1;
            margin-bottom: 30px;
            @include brk($m) {
                font-size: 16px;
                margin-bottom: 20px;
            }
        }
        .texte {
            p {
                color:#636363;
                font-size: 17px;
                line-height:1.6;

                @include brk($m) {
                    font-size: 15px;
                    text-align: center!important;
                }
            }
        }
    }
    .lien {
        margin-top: 10px;
        border: 1px solid transparentize(#000, 0.9);
        padding: 21px 55px 20px 30px;
        background:#fafafa;
        text-decoration: none;
        position: relative;
        p {
            color:$touche2;
            font-weight: $bold;
            margin-bottom: 0;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 1px;
        }
        .icon {
            position: absolute;
            color:$touche1;
            font-weight: $bold;
            font-size: 20px;
            right:22px;
            top:5px;
            height:100%;
            padding-top: 14px;
        }
        &:hover {
            background: $touche2;
            border: 1px solid $touche2;
            p, .icon  {
                color:#fff;
            }
        }
    }
}












// APPEL A ACTION ----------------------------------------------



.flex-appel {
    margin-bottom: 65px;
    border-top:1px solid transparentize(#000, 0.92);
    border-bottom:1px solid transparentize(#000, 0.92);
    .banner-content {
        padding: 10px 0 28px;
    }
    .appel-titre {
        font-size: 23.5px;
        font-family: $family2;
        text-transform: uppercase;
        color:$touche2;
        font-weight: $light;
        position: relative;
        display: inline-block;
        width: 60%;
        padding-top:17px;
        line-height: 1.4;
        letter-spacing: 1px;
        float: left;
        @include brk($t) { font-size: 21.5px; }
        @include brk($tp) {
            margin-bottom: 25px;  
            width: 100%;
        }
        @include brk($m) { 
            font-size: 20px; 
            margin-bottom: 25px;
            span {display:block;}
        }
    }
    .btn { 
        float: right;
        display: inline-block;
        position: relative;
        background:#fafafa !important;
        border:1px solid transparentize(#000, 0.9);
        padding: 21px 75px 12px 30px;
        height:68px;
        @include brk($tp) {
            float: left;
        }
        p {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: $bold;
            letter-spacing: 1px;
            line-height: 1;
            color:$touche2;
            position: relative;
            display:inline-block;
        }
        .icon-download {
            width:14px;
            height:14px;
            margin-left: 23px;
            margin-right: -15px;
            transform: translate3d(0,5px,0);
            // position: absolute;
            // left:0;
            // top:23px;
            display:inline-block;
            path, rect {fill:#fff; transition: transform 0.3s;}
            path {transform: translate3d(0,0,0);}
        }
        .icon {
            position: absolute;
            color:$touche1;
            font-size: 20px;
            right:22px;
            top:6px;
            height:100%;
            padding-top: 14px;
            user-select:none;
        }

        &:hover {
            background:$touche2 !important;
            p {
                color:#fff;
            }
            path, rect {fill:#2e2b26}
            path {transform: translate3d(0,2px,0);}
        }
    }
}




















// FLEX CARTES ----------------------------------------------




.flex-cartes {
    margin-bottom: 70px;
    .content>ul {
        // display: inline-flex; /* or inline-flex */
        // justify-content: space-between;
        // flex-direction: column;
        >li {
            width:49%;
            min-height:410px;
            float: left;
            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
            padding:45px 30px 25px 40px;
            margin-bottom: 20px;
            &:nth-child(2n){float:right}
            @include brk($m) {
                padding:30px 25px 25px 25px;
                width:100%;
            }
            .icon-container {
                width:46px;
                height:46px;
                margin-bottom: 40px;
                background-size: contain;
                @include brk($m) {
                    margin-bottom: 35px;
                }
            }
            .titre {
                font-size: 21px;
                font-weight: $normal;
                margin-bottom: 35px;
            }
            .texte {
                li {
                    line-height: 1.45;
                }
                p, li, a {
                    font-size: 14px;
                    color:#424242;
                }
            }
        }
    }
}


















// FLEX TITRE | TEXTES  (Dérives) -----------------------------------------


.flex-titre_textes {
    background:#f8f8f8;
    padding:80px 0 75px;
    margin-bottom: -6px;
    @include brk($t) { 
        padding:50px 0 55px;
    }
    .fond {
        height:400px;
        position: absolute;
        width:50%;
        right:0;
        bottom:0px;
        animation: fade 2s;
        background-size:contain;
        background-position:0% 100%;
        @include brk($t) { 
            display: none;
        }
    }
    .titres-container {
        width:40%;
        display: inline-block;
        float: left;
        margin-bottom:0px;
        @include brk($t) { 
            width:100%;
        }
        .sur-titre {
            font-size: 14px;
            font-weight: $bold;
            font-family: $family2;
            color:$touche1;
            animation: fade 2.5s;
            text-transform: uppercase;
            letter-spacing: 4px;
            z-index: 4;
            user-select:none;
            margin-bottom: 20px;
            display: inline-block;
            @include brk($t) { 
            }
            @include brk($m) { 
                font-size: 10px; 
                margin-bottom: 10px;
            }
            
            position: relative;
            // TRAIT ------------------------
            &:after {
                content:"";
                position: absolute;
                top:10px;
                left:calc(100% + 15px);
                height:1px;
                width:70px;
                opacity: 1;
                transition: width 0.1s, opacity 0.5s;
                display: inline-block;
                background: transparentize($touche1, 0);
                @include brk($m) {
                    top:8px;
                    // display:none;
                }
            }
        }
        .titre {
            font-size:30px;
            font-weight: $bold;
            font-family: $family1;
            color:$touche2;
            animation: fade 2s;
            line-height: 1.1;
            letter-spacing: 0px;
            position: relative;
            // text-transform: uppercase;
            z-index: 4;
            user-select:none;
            margin-bottom: 40px;
            // display: none;
            @include brk($t) { font-size: 30px; margin-bottom: 25px;}
            @include brk($m) { font-size: 25px; }
        }
        .sous-titre {
            p {            
                font-size:17px;
                font-weight: $normal;
                font-family: $family1;
                color:$touche1;
                animation: fade 2s;
                line-height: 1.6;
                letter-spacing: 0px;
                position: relative;
                // text-transform: uppercase;
                z-index: 4;
                user-select:none;
                margin-bottom: 40px;
                // display: none;
                @include brk($t) { font-size: 17px; }
                @include brk($m) { font-size: 17px; }
            }
        }
    }
    .lien {
        margin-top: 10px;
        border: 1px solid transparentize(#000, 0.9);
        padding: 25px 75px 12px 35px;
        margin-right: 75px;
        background:#f4f4f4;
        text-decoration: none;
        position: relative;
        // float: right;
        height:68px;
        @include brk($t) {
            margin-top: 20px;
            margin-bottom: 10px;
            margin-right: 0px;
            float: left;
        }
        @include brk($m) {
            float: none;
        }
        p {
            color:$touche2;
            font-weight: $bold;
            margin-bottom: 0;
            text-transform: uppercase;
            font-size: 12px;
        }
        .icon {
            position: absolute;
            color:$touche1;
            font-size: 20px;
            right:22px;
            top:6px;
            height:100%;
            padding-top: 14px;
            user-select:none;
        }
        &:hover {
            background: $touche2;
            border: 1px solid $touche2;
            p, .icon  {
                color:#fff;
            }
        }
    }
    .col.col2 {
        width:54%;
        display: inline-block;
        float: right;
        @include brk($t) { width:100%; }
        .ul-liste {
            padding-left:40px;
            @include brk($m) { 
                padding-left:20px;
            }
        }
        .ul-liste>li {
            position: relative;
            display: inline-block;
            display: inline-block;
            width:100%;
            border: 1px solid transparentize(#fff, 0.9);
            margin-bottom: 17px;
            padding: 0px 60px 18px 0px;
            border-bottom: 1px solid transparentize(#000, 0.9);
            @include brk($m) { 
                padding: 0px 10px 18px 0px;
            }
            &:last-child {
                border:none;
            }
            .date {
                font-size: 15px;
                font-weight: $bold;
                font-family: $family2;
                color:$touche1;
                animation: fade 2.5s;
                text-transform: uppercase;
                letter-spacing: 4px;
                display: inline-block;
                padding-bottom: 0;
                margin-bottom: 9px;
                display: none;
                @include brk($t) { 
                }
                @include brk($m) { 
                    font-size: 10px; 
                    margin-bottom: 10px;
                }
            }
            .texte p {
                font-size: 18.5px;
                padding-bottom:0;
                margin-bottom: 0;
                color:$touche2;
                @include brk($m) { 
                    font-size: 15px;
                }
            }
            .icon-container {
                display: inline-block;
                width:6px;
                height:6px;
                border-radius:50%;
                position: absolute;
                left:-37px;
                top:7px;
                text-align: center;
                cursor: pointer;
                color:$touche1;
                user-select:none;
                // transform: scale(1.05);
                transition: transform 0.3s;
                @include brk($m) { 
                    left:-19px;
                }
            }
        }
        .cadre {
            margin-top: 25px;
            padding: 30px 65px 30px 45px;
            border:1px solid transparentize($touche1, 0.5);
            display: none;
            @include brk($m) { 
                padding: 30px 30px 30px 30px;
                text-align: center;
            }
            .titre-container {
                position: relative;
                margin-bottom: 25px;
                @include brk($m) { 
                    margin-bottom: 20px;
                }
                a, p {
                    font-size: 15px;
                    line-height: 1.75;
                    font-weight: $bold;
                    font-family: $family2;
                    letter-spacing: 0.7px;
                    color:#1e1e1e;
                    text-transform: uppercase;
                }   
                a {
                    -webkit-text-stroke:#1e1e1e;
                    text-decoration: underline;
                    &:hover {
                        color:$touche1;
                    }
                }      
            }
            .texte {
                p {
                    font-size: 15px;
                    line-height: 1.7;
                    @include brk($m) { 
                        font-size: 12px;
                    }
                }
            }
        }        
    }
}

