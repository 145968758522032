

.page-accueil {
    background: #fff;
}



// ACCUEIL HEIGHT BANNER TOP

$pgac_bT_h-desktop: 550px;
$pgac_bT_h-laptop: 530px;
$pgac_bT_h-tablet: 455px;
$pgac_bT_h-mobile: 317px;

$image_height-tablet : 502px;
$image_height-mobile : 210px;

.page-accueil .banner.top {
    background: darken(#0f205a, 5%);
    height:$pgac_bT_h-desktop;
    transition: height 1s;
    @include brk ($l) { height:$pgac_bT_h-laptop; }
    @include brk ($t) { height:$pgac_bT_h-tablet; }
    @include brk ($m) { height:$pgac_bT_h-mobile; }
    .bloc-container {
        line-height: $pgac_bT_h-desktop;
        transition: line-height 1s;
        @include brk ($l) { line-height:$pgac_bT_h-laptop; }
        @include brk ($t) { line-height:$pgac_bT_h-tablet; }
        @include brk ($m) { line-height:$pgac_bT_h-mobile; }
    }
}






.page-accueil .banner.top {
    .image {
        background-position:50% 85%;
        &::after {
            @extend .overlay;
            content:"";
            display: none;
            background: transparentize($touche2, 0.3);
        }
        @include brk($t) {
            width:100%;
        }
        @include brk($m) {
            background-position: 60% 50%;
            &::after {
                display: inline-block;
            }
        }
    }
    .bloc-container {
        // display: none;
        .bloc {
            // width:40%;
            padding:0px 0% 40px;
            @include brk($t) {
                width:100%;
                padding:0px 0% 0;
            }
            @include brk($m) {
                padding:0px 0% 0;
            }
            .textes {
                .sur-titre {
                    animation: fade 1.5s;
                    > p {
                        font-size: 22px;
                        font-weight: $light;
                        font-family: $family1;
                        animation: fade 1s;
                        color: transparentize(#fff, 0);
                        text-transform: uppercase;
                        letter-spacing: 0.2px;
                        line-height: 2;
                        padding: 0 12%;
                        user-select:none;
                        margin-bottom: 10px;
                        transform: translate3d(0,0,0); 
                        &.move_up { animation: move_up_15 1s; }
                        @include brk($t) {
                            padding: 0 0;
                        }
                        @include brk($m) {
                            font-size: 10.5px;
                            // display: none;
                        }
                    }
                }
                .titre {
                    animation: fade_move 1.5s;
                    font-size: 57.5px;
                    font-weight: $light;
                    font-family: $family1;
                    clear: both;
                    color: transparentize(#fff, 0);
                    display: inline-block;
                    letter-spacing: 0.3px;
                    text-transform: uppercase;
                    word-spacing: 2px;
                    margin-bottom: 10px;
                    position: relative;
                    line-height: 1.2;
                    user-select:none;
                    transform: translate3d(0,0,0); 
                    &.move_up { animation: move_up_10 1s; }
                    @include brk($t) {
                        font-size: 42.5px;
                    }
                    @include brk($m) {
                        font-size: 29px;
                        line-height: 1.2;
                        margin-bottom: 0px;
                    }
                    b, strong, span {
                        font-weight: $bold;

                    }
                }
                .chapeau, .sous-titre {
                    animation: fade 2s;
                    p {                    
                        font-size: 42.5px;
                        font-weight: $light;
                        font-family: $family1;
                        color:$touche1;
                        line-height: 1.18;
                        user-select:none;
                        transform: translate3d(0,0,0); 
                        &.move_up { animation: move_up_5 1s; }
                        @include brk($t) {
                            font-size: 40px;
                        }
                        @include brk($m) {
                            font-size: 27.5px;
                        }
                    }
                }
                .lien {
                    margin-top: 40px;
                    height:60px;
                    transform: translate3d(0,0,0); 
                    background:none !important;
                    .inside {
                        animation: fade 2.5s;
                        background: $touche1;
                        border-radius:28px;
                    }
                    &.move_up { animation: move_up_5 1s; }
                    @include brk($m) {
                        margin-top: 20px;
                    }
                    p {
                        font-size: 17px;
                        font-weight: $bold;
                        font-family: $family1;
                        color:$touche1;
                        padding-top:18px;
                        text-transform: uppercase;
                    }
                    &::after {
                        content:"";
                        position: absolute;
                        bottom:-6px;
                        left:0px;
                        height:3px;
                        width:0;
                        opacity: 0;
                        transition: width 0.1s, opacity 0.5s;
                        display: inline-block;
                        background: #fff;
                        @include brk($td) {
                            width:100%;
                            opacity: 1;
                        }
                    }
                    &:hover {
                        .inside {
                            // background:$touche2;
                        }
                        &::after {
                            opacity: 1;
                            width:100%;
                        }
                    }
                }
            }
        }
    }
}





// SLIDER ------------------------------------------

.page-accueil #slider_home_top {
    height: 100%;
}

// SIDE BUTTONS ----------------------------------

.page-accueil .banner.top .side-btn {
	animation: fade 2s;
    position:absolute;
    height:100%;
    width:30%;
    z-index: 10;
    top:0;
    margin-top: ($header-height / 2);
    display:inline-block;
    cursor: pointer;
    display: none;
    @include brk($t) {display: none!important;}
    svg {
    	position: absolute;
    	top:calc(50% - 91px);
    	width:90px;
    	height:90px;
    }
    path {
    	fill:transparentize(#fff,0.6);
    	transition: fill 1s;
    }
	polygon {
		fill:#555859;
		opacity:0.6;
		transition: opacity 1.5s;
	}
    &.left {
    	left:0px;
    	svg {left:0; transform:rotate(180deg);}
    }
    &.right {
    	right:0px;
    	svg {right:0}
    }
    &:hover {
    	path {fill:transparentize(#fff,0.0);}
    	polygon {opacity:1}
    }
}










// SLIDER CONTROL PREV / NEXT  ----------------------------------

.slider-control-prev-next {
    height:100px;
    width:200px;
    position: absolute;
    left:calc(50% - 100px);
    bottom:0;
    z-index: 10;
    animation: fade 2s;
    display: none;

    // transform: rotate(90deg);
    // left:calc(50% - 750px);
    // bottom:calc(50% - 50px);

    @include brk($t) {
        top:302px;
        bottom:auto;
        left:auto;
        right:-50px;
        transform: rotate(90deg);
    }
    @include brk($m) {
        right:-65px;
        top:102px;
    }
    .inside {
        .control {
            width:100px;
            height:100px;
            line-height: 100px;
            display: inline-block;
            text-align: center;
            &:hover svg {transform: scale(0.5)!important}
            .icon {
                @extend .bloc;
                height:40px;
                width:40px;
                svg {
                    transition: transform 0.3s;
                    transform: scale(0.42);
                    polygon { fill:#fff; }
                }
            }
            &.prev {
                float:left; 
                padding-left:35px;
                .icon {transform: rotate(180deg)}
            }
            &.next {
                float:right;
                padding-right:35px;
            }
        }
    }
}











// VIGNETTES ------------------------------------------

.banner.vignettes {
    height: 440px;
    margin-top: -6px;
    margin-bottom:40px;
    @include brk($t) {
        height:auto;
        padding-bottom:0px;
        background: #fff;
        margin-bottom:0px;
    }
    @include brk($m) {
        padding-bottom:20px;
        margin-bottom:40px;
    }
    .shadow-side {
        display: inline-block;
        height:40px;
        width: 100%;
        position: absolute;
        top:-40px;
        left:0px;
        z-index:100;
        background-position:50% 100%;
        background-size: contain;
        @include brk($m) {
            top:-35px;
        }
    }
    .bloc-titre-section {
        position: absolute;
        top: -70px;
        left:20px;
        .icon-container {
            height:30px;
            width:30px;
            position: absolute;
            top:-3px;
            left:0px;
            animation-name: fade_move;
            animation-duration: 1s;
            animation-delay: 0.2s;
            .st0 {stroke:#fff;}
            @include brk($m) {
                .st0 {stroke:$touche2;}
                top:22px;
            }
        }
        .titre-section {
            color:#fff;
            font-size: 15px;
            font-weight: $bold;
            padding-left:48px;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            animation-name: fade_move;
            animation-duration: 1.8s;
            animation-delay: 0.2s;

        }
        @include brk($t) {
        }
        @include brk($m) {
            padding:25px 0 40px;
            top: -65px;
            left:0;
            .titre-section {
                font-size: 12px;
                color:$touche2;
                letter-spacing: 0.8px;
            }
        }
    }
    .banner-content {
        >.content {
            background:#fff;
            transform: translateY(-60px);
            z-index: 100;
            padding:8px 20px;
            padding-top:22px;
            @include brk($t){
            }
            @include brk($m){
                margin-top: 80px;
                transform: translateY(0px);
            }
        }
    }
    ul {
        position: relative;
        display: inline-block;
        width: calc(100% + 2px);
        min-height:275px;
        // animation: fade 1s;
    }
    li {
        width:16.66%;
        height:275px;
        z-index:10;
        display: inline-block;
        padding-right:2px;
        position: absolute;
        top:0;
        transition: left 1s, top 1s, transform 0.5s;


        @keyframes move_vignettes_up {
            0% { opacity: 0; transform:translate3d(0,30px,0) scale(0.95) }
            30% { opacity: 0.8; }
            100% { opacity: 1; transform:translate3d(0,0px,0) scale(1) }
        }
        @for $i from 1 through 10 {
            &:nth-child(#{$i}) { 
                animation-delay: ((0.05s * $i) - 0.05s);
                animation-duration: 0.5s + (0.16s * $i);
                animation-name: move_vignettes_up;
            }
        }


        &:nth-child(1) {left:0%;}
        &:nth-child(2) {left:16.66%;}
        &:nth-child(3) {left:33.33%;}
        &:nth-child(4) {left:49.99%;}
        &:nth-child(5) {left:66.66%;}
        &:nth-child(6) {left:83.33%;}
        @include brk($t) {
            // position: relative;
            left:0% !important;
            top:auto !important;
            position: relative;
            float: left;
            margin-bottom: 2px;
            width:calc(33.33%);
        }
        @include brk($tp) {
            width:calc(50%);
        }
        @include brk($m) {
            width: 100%;
            left:0% !important;
            top:auto !important;
            text-align: center;
            height:162px;
            position: relative;
            margin-bottom: 2px;
        }
        a {
            // padding: 100px 15% 80px;
            position: relative;
            display: inline-block;
            height:100%;
            width: 100%;
            transition: transform 0.3s;
            background-color: #fff;
            text-align: center;
            @include brk($t) {
                // padding: 10px 15% 50px 200px;
            }
            @include brk($m) {
                // padding: 160px 12% 45px;
            }
        }
        .gradient {
            position: absolute;
            bottom:0;
            left:0;
            right:0;
            height:150px;
            // animation: fade 0.15s;
            background: linear-gradient(to top, 
                transparentize(#000, 0.2) 10%, 
                transparentize(#000, 0.55) 50%, 
                transparentize(#000, 0.9) 80%, 
                transparentize(#000, 1) 100%);
        }
        .bloc-titre {
            display: inline-block;
            position: absolute;
            left:0;
            right:0;
            bottom:0;
            .titre {
                font-size:12.5px;
                font-weight: $normal;
                font-family: $family1;
                color:#fff;
                position: relative;
                text-transform: uppercase;
                display: inline-block;
                padding-bottom: 20px;
                animation: fade 2s;
                @include brk($t) {
                    padding-top:10px;
                    padding-bottom: 10px;
                }
                @include brk($m) {
                    padding-top:0px;
                    padding-bottom: 5px;
                }
            }
        }
        .hover-container {
            display: none;
            height:275px;
            line-height:275px;
            @include brk($m) {
                height:162px;
                line-height:162px;
            }
            .texte {
                font-size:13px;
                position: relative;
                line-height: 1.7;
                color:#727272;
                font-weight: $normal;
                letter-spacing: 1px;
                text-transform: uppercase;
                animation: fade 0.5s;
                padding: 0 10% 10px;
                @extend .bloc;
            }
            .btn-container {
                position: absolute;
                width:100%;
                left:0;
                bottom:20px;
                animation: fade 0.6s;
                // display: none;
                button {
                    padding:10px 5px;
                    border-radius: 7px;
                    display: inline-block;
                    width:158px;
                    transition: background 0.5s, width 0.4s, border-radius 0.4s;
                    p {
                        color:$touche1 !important;
                        font-size:11px;
                        font-weight: $bold;
                        text-transform: uppercase;
                        span {
                            margin-left: 5px;
                        }
                    }
                    .icon {
                        position: absolute;
                        width:20px;
                        height:20px;
                        bottom:12px;
                        left:calc(50% - 60px);
                        .st0{fill:#FFFFFF;}
                    }
                }
            }
        }
        a:hover {
            transition: transform 0.3s;
            background:$touche1;
            transform: translate3d(0,5px,0);
            .bloc-titre {
                display: none;
            }
            .hover-container {
                display: inline-block;
                background: transparentize($touche2, 0.3);
            }

            @include brk($t) {
                transform: translate3d(0,0px,0);
            }

            // .image-container { transform: scale(0.95); }z

            .titre, .texte {
                color:#fff;
            }

            button {
                width:165px;
                border-radius:0px;
                .st0 {fill:inherit!important}
            }
        }
        &:nth-child(1) {
            .ombre-top,
            .ombre.side.right { display: none!important; }
        }
        &:nth-child(2) {

        }
        &:nth-child(3) {
            .ombre.side.left {
                display: none;
            }
        }
        .ombre-top {
            position: absolute;
            animation: fade 3s;
            background-size:contain;
            background-position: 50% 100%;
            width:calc(100% - 200px);
            opacity: 0.7;
            height:30px;
            left: 200px;
            right:0;
            top:-35px;
            background-image:url('../assets/images/ombre-vignette-tablet.png');
        }
    }
    .ombre {
        position: absolute;
        animation: fade 3s;
        background-size:contain;
        &.side {
            background-position: 100% 0%;
            width:40px;
            height:100%;
            top:0;
            background-image:url('../assets/images/ombre-vignette-side.png');
            filter: saturate(0%);
            @include brk($m) {
                width:30px;
                height:90%;
            }
            &.left {
                left:-40px;
                @include brk($m) {
                    left:-30px;
                }
            }
            &.right {
                right:-40px;
                transform: rotate(180deg);
                @include brk($m) {
                    right:-30px;
                }
            }
        }
    }

    .cta-container {
        margin-top: 40px;
        @include brk($t) {
            margin-top: 50px;
        }
        @include brk($m) {
            text-align:center;
        }
        .titre {
            font-size: 23.5px;
            text-transform: uppercase;
            color:$touche2;
            display: inline-block;
            padding-left: 40px;
            padding-top: 25px;
            float: left;
            @include brk($t) {
                font-size: 22.5px;
                width: 50%;
                line-height:1.3;
                padding-left: 4px;
            }
            @include brk($m) {
                width: 100%;
                font-size: 17.5px;
            }
        }
        .lien {
            margin-top: 10px;
            border: 1px solid transparentize(#000, 0.9);
            padding: 24px 85px 12px 35px;
            margin-right: 75px;
            background:#fafafa;
            text-decoration: none;
            position: relative;
            float: right;
            height:68px;
            @include brk($t) {
                margin-top: 26px;
                margin-right: 0px;
            }
            @include brk($m) {
                float: none;
                width: 100%;
                padding: 25px 65px 12px 35px;
            }
            p {
                color:$touche2;
                font-weight: $bold;
                margin-bottom: 0;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 12px;
            }
            .icon {
                position: absolute;
                color:$touche1;
                font-size: 20px;
                right:22px;
                top:5px;
                height:100%;
                padding-top: 14px;
                user-select:none;
            }
            &:hover {
                background: $touche2;
                border: 1px solid $touche2;
                p, .icon  {
                    color:#fff;
                }
            }
        }
    }
}





.page-accueil .banner.etapes {
    margin-bottom: 120px;
    height:550px;
    @include brk($t) {
        height:auto;
        margin-bottom: 80px;
    }
    @include brk($m) {
        margin-bottom: 25px;
    }
    .image {
        width: 76%;
        right:-14%;
        left:auto;
        @include brk($t) {
            width: 120%;
            position: relative;
            right:-10%;
            left:-10%;
            height:475px;
        }
        @include brk($m) {
            display: none!important;
        }
    }
    .cadran {
        margin-top: 50px;
        box-shadow: 60px 4px 50px 0px rgba(0, 0, 0, 0.2);
        display: inline-block;
        padding:40px 40px 30px;
        padding-left: 0;
        padding-right: 70px;
        position: relative;
        background:#fff;
        @include brk($t) {
            width: 100%;
            box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.2);
            padding-left: 40px;
            margin-top: -50px;
        }
        @include brk($m) {
            margin-top: 0px;
            padding:40px 9%;
            text-align: center;
            padding-bottom:20px;
        }
        .titre-section {
            font-size: 31px;
            color:$touche2;
            text-transform: uppercase;
            margin-bottom: 40px;
            @include brk($m) {
                font-size: 20px;
                margin-bottom: 30px;
            }
        }
        ul {
            li {
                display: block;
                padding:10px;
                padding-left:0;
                position: relative;
                .icon-container {
                    position: absolute;
                    left:0;
                    top:0;
                    bottom:0;
                    height:70px;
                    width:70px;
                    @include brk($m) {
                        position: relative;
                        margin: 0 auto;
                        height:68px;
                        width:68px;
                        margin-bottom: 20px;
                    }
                }
                .textes-container {
                    padding-bottom:20px;
                    margin-left:100px;
                    border-bottom:1px solid transparentize(#000, 0.95);
                    @include brk($m) {
                        margin-left:0px;
                        border-bottom:none;
                        padding-bottom:10px;
                    }
                    .titre {
                        font-size: 16.5px;
                        font-weight: $bold;
                        color:$touche2;
                        text-transform: uppercase;
                        @include brk($m) {
                            font-size: 13px;
                            margin-bottom: 7px;
                        }
                    }
                    .sous-titre {
                        font-size: 16px;
                        color:#898989;
                        font-weight: $light;
                        line-height:1.45;
                    }
                }
                &:last-child .textes-container {border:none;}
            }
        }
    }
}







.page-accueil .banner.raccourcis {
    margin-bottom: 100px;
    @include brk($m) {
        margin-bottom: 50px;
    }
    ul {
        width: calc(100% + 20px);
        li {
            display: inline-block;
            width:33.33%;
            float: left;
            height:115px;
            padding-right:20px;
            @include brk($t) {
                width: 100%;
                margin-bottom: 1px;
            }
            a {
                background:$touche2;
                .svg-container {
                    width:30px;
                    height:30px;
                    position: absolute;
                    top:43px;
                    left:22px;
                    transition: transform 0.3s;
                }
                p {
                    color:#fff;
                    font-size: 14px;
                    text-transform: uppercase;
                    padding-top:47px;
                    padding-left:96px;
                    &::before {
                        content:"";
                        height:72px;
                        width:1px;
                        background: transparentize(#fff, 0.88);
                        position: absolute;
                        top:22px;
                        left:70px;
                    }
                }
                .icon {
                    position: absolute;
                    right:30px;
                    top:38px;
                    font-size: 30px;
                    font-weight: $light;
                    color:#fff;
                    transition: transform 0.2s;
                    user-select:none;
                    @include brk($m) {
                        display: none;
                    }
                }
                &:hover {
                    background: darken($touche2, 20%);
                    .icon {
                        transform: translateX(2px);
                    }
                }
            }
        }
    }
}







.page-accueil .banner.services {
    margin-bottom: 120px;
    @include brk($m) {
        margin-bottom: 35px;
    }
    .titre-section {
        font-size: 27.5px;
        color:$touche2;
        font-weight: $light;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 55px;
        @include brk($t) {
            margin-bottom: 40px;
        }
        @include brk($m) {
            margin-bottom: 30px;
        }
    }
    ul {
        width:calc(100% + 15px);
        li {
            height:332px;
            display: inline-block;
            width: 50%;
            float: left;
            padding-right:15px;
            @include brk($t) {
                width: 100%;
                margin-bottom: 20px;
            }
            a {
                position: relative;
                .image {
                    filter: brightness(90%);
                    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
                    transition: filter 0.2s;
                    .gradient {
                        position: absolute;
                        bottom:0;
                        left:0;
                        right:0;
                        height:150px;
                        // animation: fade 0.15s;
                        background: linear-gradient(to top, 
                            transparentize(#000, 0.3) 10%, 
                            transparentize(#000, 0.4) 30%, 
                            transparentize(#000, 0.6) 50%, 
                            transparentize(#000, 0.82) 70%, 
                            transparentize(#000, 1) 100%);
                    }
                }
                .titre {
                    padding:10px;
                    padding-left:40px;
                    font-size: 16px;
                    position: absolute;
                    width: 100%;
                    bottom:22px;
                    left:0;
                    color:#fff;
                    text-transform: uppercase;
                    line-height:1;
                    span {
                        font-size: 20px;
                        position: absolute;
                        right:20px;
                        top:8px;
                        user-select:none;
                    }
                    &::before {
                        content:"";
                        position: absolute;
                        background: $touche1;
                        top:0px;
                        left:0px;
                        height:calc(100%);
                        width:7px;
                        animation: fade 2s;
                        @include brk($tp) {
                            left:1px;
                        }
                    }
                }
            }
            a:hover {
                .image {
                    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.35);
                    filter: brightness(100%);
                }
                .lire-la-suite {
                    opacity: 1;
                    // color:$orange;
                }
            }
        }
    }
}

